import { createApp } from "vue";
import { createHead } from "@vueuse/head";

import App from "@/app/App.vue";
import router from "@/app/router";
import store from "@/store";
import "@/firebase";

import "@/index.css";
import Constants from "@/../../plugin/Constants.js";
import PrefectureCode from "@/../../plugin/PrefectureCode.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

const app = createApp(App);
const head = createHead()
app.use(router).mount("#app");
app.use(Constants);
app.use(PrefectureCode);
app.use(store);
app.use(head);
app.component("font-awesome-icon", FontAwesomeIcon);
