// constsPlugin.js
const CONSTS = {
  ACTIVE: 1,
  INACTIVE: 0,

  HOSPITAL_TYPE: [0, 1, 2, 3],
  HOSPITAL_TYPE_MAP: {
    0: "",
    1: "総合周産期母子医療センター",
    2: "地域周産期母子医療センター",
    3: "助産院",
  },

  //　出産前
  ROOM_TYPE: ["大部屋", "個室", "特別室"],
  CLINICAL_DEPARTMENT_TYPE: ["小児科", "NICU", "MFICU", "救命救急センター","LDR"],
  TYPE_OF_INSTRUCTION: ["母乳指導", "沐浴指導", "母親学級", "父親学級", "両親学級"],
  PRENATAL_DIAGNOSIS: ["遺伝カウンセリング", "羊水検査", "クアトロテスト", "絨毛検査", "胎児心拍数モニタリング(NST)", "NIPT", "4Dエコー"],

  // 出産中
  CHILD_BIRTH: ["自然分娩", "計画分娩", "帝王切開", "和痛・無痛分娩", "24時間和痛・無痛分娩対応", "ソフロロジー法", "フリースタイル"],
  EXTRA_SERVICE: ["お祝い御膳", "エステ", "マタニティヨガ", "子連れ入院","コンシェルジュ","院内見学"],

  // 出産後
  BABY_ROOM: ["早期母子接触","新生児室", "母子同室", "母子別室", "預かり可能"],
  PYMENT_METHOD: ["予約金", "直接支払制度", "現金", "クレジットカード"],
  POSTPARTUM: ["母乳外来", "助産師外来"],
  OTHERS:["産科医療補償制度", "さい帯血バンク"],

  HOSPITAL_SERVICE: [
    "AttendingBirth",
    "VideoShooting",
    "ChildSharingRoom",
    "PainlessDelivery",
    "JCQHCHospital",
    "DirectPayment",
    "FemaleDoctor",
    "HomeComing",
    "BreastfeedingInstruction",
    "PrivateRoom",
    "MidwiferyOutpatient",
    "MFICU",
    "NICU",
    "CriticalCareCenter",
    "HypothermiaTherapy",
    "UterineArteryEmbolization",
  ],
  HOSPITAL_SERVICE_MAP: {
    AttendingBirth: "立ち会い",
    VideoShooting: "ビデオ撮影",
    ChildSharingRoom: "母子同室",
    PainlessDelivery: "無痛分娩",
    JCQHCHospital: "産科医療補償制度加入",
    DirectPayment: "直接支払い制度あり",
    FemaleDoctor: "女医医師がいる",
    HomeComing: "里帰り出産可能",
    BreastfeedingInstruction: "母乳指導",
    PrivateRoom: "個室あり",
    MidwiferyOutpatient: "助産師外来",
    MFICU: "MFICU",
    NICU: "NICU",
    CriticalCareCenter: "救命救急センター",
    HypothermiaTherapy: "低体温療法",
    UterineArteryEmbolization: "動脈塞栓術",
  },

  MOTIVATE_TYPE : [
    "AttendingBirth",
    "VideoShooting",
    "ChildSharingRoom",
    "PainlessDelivery",
    "JCQHCHospital",
    "DirectPayment",
    "FemaleDoctor",
    "HomeComing",
    "BreastfeedingInstruction",
    "PrivateRoom",
    "MidwiferyOutpatient",
    "GoodDish",
    "GoodCompatibility"
  ],
  MOTIVATE_TYPE_MAP: {
    AttendingBirth: "立ち会い",
    VideoShooting: "ビデオ撮影",
    ChildSharingRoom: "母子同室",
    PainlessDelivery: "無痛分娩",
    JCQHCHospital: "産科医療補償制度加入",
    DirectPayment: "直接支払い制度あり",
    FemaleDoctor: "女医医師がいる",
    HomeComing: "里帰り出産可能",
    BreastfeedingInstruction: "母乳指導",
    PrivateRoom: "個室あり",
    MidwiferyOutpatient: "助産師外来",
    Neighborhood: "家から近い",
    ParentsHouse: "実家から近い",
    GoodDish: "食事がおいしい",
    GoodCompatibility: "先生との相性がよかった"
  },
};

let Consts = {};

Consts.install = function(app) {
  app.config.globalProperties.$getConst = (key) => {
    return CONSTS[key];
  };
};

export default Consts;
