import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Top",
    component: () => import("@/app/views/Top.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/app/views/Terms.vue"),
    meta: {
      title: "利用規約",
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () => import("@/app/views/PrivacyPolicy.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/app/views/SignUp.vue"),
  },
  {
    path: "/hospital/list/",
    name: "HospitalList",
    component: () => import("@/app/views/Hospital/List.vue"),
  },
  {
    path: "/hospital/detail/:HospitalId/",
    name: "HospitalDetail",
    component: () => import("@/app/views/Hospital/Detail.vue"),
  },
  {
    path: "/board/birth/:BoardDate/:Page/",
    name: "BoardBirth",
    component: () => import("@/app/views/Board/Birth.vue"),
  },
  {
    path: "/posts/:PostId/",
    name: "Posts",
    component: () => import("@/app/views/Posts.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: () => import("@/app/views/NotFound.vue"),
  },
];

const DEFAULT_TITLE = "べびなび";
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title
    ? to.meta.title + " | " + DEFAULT_TITLE
    : DEFAULT_TITLE;
  document.path = to.path;
});

export default router;
