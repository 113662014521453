// constsPlugin.js
const CONSTS = {
  PREFECTURE_CODE_MAP: {
    "47": "沖縄県",
    "30": "和歌山県",
    "23": "愛知県",
    "22": "静岡県",
    "15": "新潟県",
    "05": "秋田県",
    "04": "宮城県",
    "27": "大阪府",
    "11": "埼玉県",
    "01": "北海道",
    "14": "神奈川県",
    "42": "長崎県",
    "43": "熊本県",
    "38": "愛媛県",
    "32": "島根県",
    "33": "岡山県",
    "09": "栃木県",
    "19": "山梨県",
    "20": "長野県",
    "28": "兵庫県",
    "16": "富山県",
    "06": "山形県",
    "40": "福岡県",
    "37": "香川県",
    "10": "群馬県",
    "35": "山口県",
    "46": "鹿児島県",
    "29": "奈良県",
    "31": "鳥取県",
    "34": "広島県",
    "18": "福井県",
    "08": "茨城県",
    "26": "京都府",
    "12": "千葉県",
    "13": "東京都",
    "39": "高知県",
    "03": "岩手県",
    "02": "青森県",
    "25": "滋賀県",
    "41": "佐賀県",
    "44": "大分県",
    "36": "徳島県",
    "45": "宮崎県",
    "17": "石川県",
    "21": "岐阜県",
    "07": "福島県",
    "24": "三重県",
  },
  PREFECTURE_CODES: [
    {
      text: "北海道",
      value: "01",
      city: [
        { value: "01100", text: "札幌市" },
        { text: "札幌市中央区", value: "01101" },
        { value: "01102", text: "札幌市北区" },
        { value: "01103", text: "札幌市東区" },
        { text: "札幌市白石区", value: "01104" },
        { text: "札幌市豊平区", value: "01105" },
        { text: "札幌市南区", value: "01106" },
        { text: "札幌市西区", value: "01107" },
        { value: "01108", text: "札幌市厚別区" },
        { text: "札幌市手稲区", value: "01109" },
        { text: "札幌市清田区", value: "01110" },
        { text: "函館市", value: "01202" },
        { text: "小樽市", value: "01203" },
        { text: "旭川市", value: "01204" },
        { value: "01205", text: "室蘭市" },
        { text: "釧路市", value: "01206" },
        { value: "01207", text: "帯広市" },
        { value: "01208", text: "北見市" },
        { value: "01209", text: "夕張市" },
        { text: "岩見沢市", value: "01210" },
        { value: "01211", text: "網走市" },
        { text: "留萌市", value: "01212" },
        { text: "苫小牧市", value: "01213" },
        { text: "稚内市", value: "01214" },
        { text: "美唄市", value: "01215" },
        { text: "芦別市", value: "01216" },
        { value: "01217", text: "江別市" },
        { text: "赤平市", value: "01218" },
        { text: "紋別市", value: "01219" },
        { value: "01220", text: "士別市" },
        { text: "名寄市", value: "01221" },
        { value: "01222", text: "三笠市" },
        { value: "01223", text: "根室市" },
        { text: "千歳市", value: "01224" },
        { text: "滝川市", value: "01225" },
        { value: "01226", text: "砂川市" },
        { text: "歌志内市", value: "01227" },
        { text: "深川市", value: "01228" },
        { text: "富良野市", value: "01229" },
        { text: "登別市", value: "01230" },
        { value: "01231", text: "恵庭市" },
        { text: "伊達市", value: "01233" },
        { text: "北広島市", value: "01234" },
        { text: "石狩市", value: "01235" },
        { text: "北斗市", value: "01236" },
        { value: "01303", text: "石狩振興局当別町" },
        { value: "01304", text: "石狩振興局新篠津村" },
        { value: "01331", text: "渡島総合振興局松前町" },
        { value: "01332", text: "渡島総合振興局福島町" },
        { text: "渡島総合振興局知内町", value: "01333" },
        { value: "01334", text: "渡島総合振興局木古内町" },
        { value: "01337", text: "渡島総合振興局七飯町" },
        { text: "渡島総合振興局鹿部町", value: "01343" },
        { value: "01345", text: "渡島総合振興局森町" },
        { text: "渡島総合振興局八雲町", value: "01346" },
        { text: "渡島総合振興局長万部町", value: "01347" },
        { value: "01361", text: "檜山振興局江差町" },
        { text: "檜山振興局上ノ国町", value: "01362" },
        { text: "檜山振興局厚沢部町", value: "01363" },
        { text: "檜山振興局乙部町", value: "01364" },
        { text: "檜山振興局奥尻町", value: "01367" },
        { text: "檜山振興局今金町", value: "01370" },
        { value: "01371", text: "檜山振興局せたな町" },
        { text: "後志総合振興局島牧村", value: "01391" },
        { value: "01392", text: "後志総合振興局寿都町" },
        { value: "01393", text: "後志総合振興局黒松内町" },
        { value: "01394", text: "後志総合振興局蘭越町" },
        { text: "後志総合振興局ニセコ町", value: "01395" },
        { value: "01396", text: "後志総合振興局真狩村" },
        { text: "後志総合振興局留寿都村", value: "01397" },
        { text: "後志総合振興局喜茂別町", value: "01398" },
        { text: "後志総合振興局京極町", value: "01399" },
        { value: "01400", text: "後志総合振興局倶知安町" },
        { value: "01401", text: "後志総合振興局共和町" },
        { value: "01402", text: "後志総合振興局岩内町" },
        { text: "後志総合振興局泊村", value: "01403" },
        { text: "後志総合振興局神恵内村", value: "01404" },
        { text: "後志総合振興局積丹町", value: "01405" },
        { value: "01406", text: "後志総合振興局古平町" },
        { text: "後志総合振興局仁木町", value: "01407" },
        { text: "後志総合振興局余市町", value: "01408" },
        { value: "01409", text: "後志総合振興局赤井川村" },
        { text: "空知総合振興局南幌町", value: "01423" },
        { value: "01424", text: "空知総合振興局奈井江町" },
        { value: "01425", text: "空知総合振興局上砂川町" },
        { text: "空知総合振興局由仁町", value: "01427" },
        { value: "01428", text: "空知総合振興局長沼町" },
        { value: "01429", text: "空知総合振興局栗山町" },
        { value: "01430", text: "空知総合振興局月形町" },
        { text: "空知総合振興局浦臼町", value: "01431" },
        { text: "空知総合振興局新十津川町", value: "01432" },
        { value: "01433", text: "空知総合振興局妹背牛町" },
        { text: "空知総合振興局秩父別町", value: "01434" },
        { value: "01436", text: "空知総合振興局雨竜町" },
        { text: "空知総合振興局北竜町", value: "01437" },
        { text: "空知総合振興局沼田町", value: "01438" },
        { text: "上川総合振興局鷹栖町", value: "01452" },
        { text: "上川総合振興局東神楽町", value: "01453" },
        { text: "上川総合振興局当麻町", value: "01454" },
        { text: "上川総合振興局比布町", value: "01455" },
        { value: "01456", text: "上川総合振興局愛別町" },
        { text: "上川総合振興局上川町", value: "01457" },
        { value: "01458", text: "上川総合振興局東川町" },
        { value: "01459", text: "上川総合振興局美瑛町" },
        { value: "01460", text: "上川総合振興局上富良野町" },
        { value: "01461", text: "上川総合振興局中富良野町" },
        { text: "上川総合振興局南富良野町", value: "01462" },
        { value: "01463", text: "上川総合振興局占冠村" },
        { value: "01464", text: "上川総合振興局和寒町" },
        { value: "01465", text: "上川総合振興局剣淵町" },
        { value: "01468", text: "上川総合振興局下川町" },
        { value: "01469", text: "上川総合振興局美深町" },
        { text: "上川総合振興局音威子府村", value: "01470" },
        { text: "上川総合振興局中川町", value: "01471" },
        { value: "01472", text: "上川総合振興局幌加内町" },
        { value: "01481", text: "留萌振興局増毛町" },
        { text: "留萌振興局小平町", value: "01482" },
        { text: "留萌振興局苫前町", value: "01483" },
        { text: "留萌振興局羽幌町", value: "01484" },
        { value: "01485", text: "留萌振興局初山別村" },
        { text: "留萌振興局遠別町", value: "01486" },
        { text: "留萌振興局天塩町", value: "01487" },
        { text: "宗谷総合振興局猿払村", value: "01511" },
        { value: "01512", text: "宗谷総合振興局浜頓別町" },
        { text: "宗谷総合振興局中頓別町", value: "01513" },
        { text: "宗谷総合振興局枝幸町", value: "01514" },
        { text: "宗谷総合振興局豊富町", value: "01516" },
        { value: "01517", text: "宗谷総合振興局礼文町" },
        { value: "01518", text: "宗谷総合振興局利尻町" },
        { value: "01519", text: "宗谷総合振興局利尻富士町" },
        { value: "01520", text: "宗谷総合振興局幌延町" },
        { text: "オホーツク総合振興局美幌町", value: "01543" },
        { value: "01544", text: "オホーツク総合振興局津別町" },
        { value: "01545", text: "オホーツク総合振興局斜里町" },
        { text: "オホーツク総合振興局清里町", value: "01546" },
        { value: "01547", text: "オホーツク総合振興局小清水町" },
        { text: "オホーツク総合振興局訓子府町", value: "01549" },
        { value: "01550", text: "オホーツク総合振興局置戸町" },
        { value: "01552", text: "オホーツク総合振興局佐呂間町" },
        { text: "オホーツク総合振興局遠軽町", value: "01555" },
        { text: "オホーツク総合振興局湧別町", value: "01559" },
        { text: "オホーツク総合振興局滝上町", value: "01560" },
        { value: "01561", text: "オホーツク総合振興局興部町" },
        { text: "オホーツク総合振興局西興部村", value: "01562" },
        { text: "オホーツク総合振興局雄武町", value: "01563" },
        { value: "01564", text: "オホーツク総合振興局大空町" },
        { text: "胆振総合振興局豊浦町", value: "01571" },
        { text: "胆振総合振興局壮瞥町", value: "01575" },
        { value: "01578", text: "胆振総合振興局白老町" },
        { text: "胆振総合振興局厚真町", value: "01581" },
        { text: "胆振総合振興局洞爺湖町", value: "01584" },
        { value: "01585", text: "胆振総合振興局安平町" },
        { text: "胆振総合振興局むかわ町", value: "01586" },
        { text: "日高振興局日高町", value: "01601" },
        { text: "日高振興局平取町", value: "01602" },
        { text: "日高振興局新冠町", value: "01604" },
        { value: "01607", text: "日高振興局浦河町" },
        { value: "01608", text: "日高振興局様似町" },
        { value: "01609", text: "日高振興局えりも町" },
        { value: "01610", text: "日高振興局新ひだか町" },
        { text: "十勝総合振興局音更町", value: "01631" },
        { text: "十勝総合振興局士幌町", value: "01632" },
        { text: "十勝総合振興局上士幌町", value: "01633" },
        { value: "01634", text: "十勝総合振興局鹿追町" },
        { text: "十勝総合振興局新得町", value: "01635" },
        { text: "十勝総合振興局清水町", value: "01636" },
        { value: "01637", text: "十勝総合振興局芽室町" },
        { value: "01638", text: "十勝総合振興局中札内村" },
        { value: "01639", text: "十勝総合振興局更別村" },
        { text: "十勝総合振興局大樹町", value: "01641" },
        { text: "十勝総合振興局広尾町", value: "01642" },
        { text: "十勝総合振興局幕別町", value: "01643" },
        { text: "十勝総合振興局池田町", value: "01644" },
        { text: "十勝総合振興局豊頃町", value: "01645" },
        { text: "十勝総合振興局本別町", value: "01646" },
        { value: "01647", text: "十勝総合振興局足寄町" },
        { value: "01648", text: "十勝総合振興局陸別町" },
        { value: "01649", text: "十勝総合振興局浦幌町" },
        { value: "01661", text: "釧路総合振興局釧路町" },
        { text: "釧路総合振興局厚岸町", value: "01662" },
        { text: "釧路総合振興局浜中町", value: "01663" },
        { text: "釧路総合振興局標茶町", value: "01664" },
        { value: "01665", text: "釧路総合振興局弟子屈町" },
        { text: "釧路総合振興局鶴居村", value: "01667" },
        { value: "01668", text: "釧路総合振興局白糠町" },
        { value: "01691", text: "根室振興局別海町" },
        { text: "根室振興局中標津町", value: "01692" },
        { text: "根室振興局標津町", value: "01693" },
        { text: "根室振興局羅臼町", value: "01694" },
        { text: "根室振興局色丹村", value: "01695" },
        { text: "根室振興局泊村", value: "01696" },
        { text: "根室振興局留夜別村", value: "01697" },
        { text: "根室振興局留別村", value: "01698" },
        { text: "根室振興局紗那村", value: "01699" },
        { value: "01700", text: "根室振興局蘂取村" },
      ],
    },
    {
      city: [
        { text: "青森市", value: "02201" },
        { value: "02202", text: "弘前市" },
        { value: "02203", text: "八戸市" },
        { text: "黒石市", value: "02204" },
        { value: "02205", text: "五所川原市" },
        { value: "02206", text: "十和田市" },
        { text: "三沢市", value: "02207" },
        { value: "02208", text: "むつ市" },
        { text: "つがる市", value: "02209" },
        { value: "02210", text: "平川市" },
        { text: "東津軽郡平内町", value: "02301" },
        { value: "02303", text: "東津軽郡今別町" },
        { value: "02304", text: "東津軽郡蓬田村" },
        { text: "東津軽郡外ヶ浜町", value: "02307" },
        { value: "02321", text: "西津軽郡鰺ヶ沢町" },
        { value: "02323", text: "西津軽郡深浦町" },
        { value: "02343", text: "中津軽郡西目屋村" },
        { text: "南津軽郡藤崎町", value: "02361" },
        { text: "南津軽郡大鰐町", value: "02362" },
        { value: "02367", text: "南津軽郡田舎館村" },
        { value: "02381", text: "北津軽郡板柳町" },
        { value: "02384", text: "北津軽郡鶴田町" },
        { text: "北津軽郡中泊町", value: "02387" },
        { text: "上北郡野辺地町", value: "02401" },
        { value: "02402", text: "上北郡七戸町" },
        { value: "02405", text: "上北郡六戸町" },
        { value: "02406", text: "上北郡横浜町" },
        { value: "02408", text: "上北郡東北町" },
        { text: "上北郡六ヶ所村", value: "02411" },
        { value: "02412", text: "上北郡おいらせ町" },
        { value: "02423", text: "下北郡大間町" },
        { text: "下北郡東通村", value: "02424" },
        { value: "02425", text: "下北郡風間浦村" },
        { value: "02426", text: "下北郡佐井村" },
        { text: "三戸郡三戸町", value: "02441" },
        { text: "三戸郡五戸町", value: "02442" },
        { text: "三戸郡田子町", value: "02443" },
        { value: "02445", text: "三戸郡南部町" },
        { text: "三戸郡階上町", value: "02446" },
        { value: "02450", text: "三戸郡新郷村" },
      ],
      text: "青森県",
      value: "02",
    },
    {
      city: [
        { value: "03201", text: "盛岡市" },
        { value: "03202", text: "宮古市" },
        { text: "大船渡市", value: "03203" },
        { value: "03205", text: "花巻市" },
        { value: "03206", text: "北上市" },
        { value: "03207", text: "久慈市" },
        { value: "03208", text: "遠野市" },
        { text: "一関市", value: "03209" },
        { text: "陸前高田市", value: "03210" },
        { value: "03211", text: "釜石市" },
        { text: "二戸市", value: "03213" },
        { text: "八幡平市", value: "03214" },
        { text: "奥州市", value: "03215" },
        { value: "03216", text: "滝沢市" },
        { value: "03301", text: "岩手郡雫石町" },
        { text: "岩手郡葛巻町", value: "03302" },
        { text: "岩手郡岩手町", value: "03303" },
        { value: "03321", text: "紫波郡紫波町" },
        { text: "紫波郡矢巾町", value: "03322" },
        { value: "03366", text: "和賀郡西和賀町" },
        { value: "03381", text: "胆沢郡金ケ崎町" },
        { value: "03402", text: "西磐井郡平泉町" },
        { value: "03441", text: "気仙郡住田町" },
        { value: "03461", text: "上閉伊郡大槌町" },
        { value: "03482", text: "下閉伊郡山田町" },
        { value: "03483", text: "下閉伊郡岩泉町" },
        { value: "03484", text: "下閉伊郡田野畑村" },
        { text: "下閉伊郡普代村", value: "03485" },
        { text: "九戸郡軽米町", value: "03501" },
        { text: "九戸郡野田村", value: "03503" },
        { text: "九戸郡九戸村", value: "03506" },
        { value: "03507", text: "九戸郡洋野町" },
        { value: "03524", text: "二戸郡一戸町" },
      ],
      value: "03",
      text: "岩手県",
    },
    {
      value: "04",
      text: "宮城県",
      city: [
        { text: "仙台市", value: "04100" },
        { value: "04101", text: "仙台市青葉区" },
        { text: "仙台市宮城野区", value: "04102" },
        { value: "04103", text: "仙台市若林区" },
        { text: "仙台市太白区", value: "04104" },
        { text: "仙台市泉区", value: "04105" },
        { text: "石巻市", value: "04202" },
        { value: "04203", text: "塩竈市" },
        { value: "04205", text: "気仙沼市" },
        { value: "04206", text: "白石市" },
        { value: "04207", text: "名取市" },
        { value: "04208", text: "角田市" },
        { value: "04209", text: "多賀城市" },
        { text: "岩沼市", value: "04211" },
        { value: "04212", text: "登米市" },
        { text: "栗原市", value: "04213" },
        { text: "東松島市", value: "04214" },
        { text: "大崎市", value: "04215" },
        { text: "富谷市", value: "04216" },
        { text: "刈田郡蔵王町", value: "04301" },
        { value: "04302", text: "刈田郡七ヶ宿町" },
        { value: "04321", text: "柴田郡大河原町" },
        { text: "柴田郡村田町", value: "04322" },
        { text: "柴田郡柴田町", value: "04323" },
        { value: "04324", text: "柴田郡川崎町" },
        { text: "伊具郡丸森町", value: "04341" },
        { text: "亘理郡亘理町", value: "04361" },
        { value: "04362", text: "亘理郡山元町" },
        { value: "04401", text: "宮城郡松島町" },
        { text: "宮城郡七ヶ浜町", value: "04404" },
        { value: "04406", text: "宮城郡利府町" },
        { value: "04421", text: "黒川郡大和町" },
        { text: "黒川郡大郷町", value: "04422" },
        { text: "黒川郡大衡村", value: "04424" },
        { text: "加美郡色麻町", value: "04444" },
        { value: "04445", text: "加美郡加美町" },
        { text: "遠田郡涌谷町", value: "04501" },
        { text: "遠田郡美里町", value: "04505" },
        { text: "牡鹿郡女川町", value: "04581" },
        { value: "04606", text: "本吉郡南三陸町" },
      ],
    },
    {
      city: [
        { value: "05201", text: "秋田市" },
        { value: "05202", text: "能代市" },
        { value: "05203", text: "横手市" },
        { value: "05204", text: "大館市" },
        { value: "05206", text: "男鹿市" },
        { text: "湯沢市", value: "05207" },
        { text: "鹿角市", value: "05209" },
        { text: "由利本荘市", value: "05210" },
        { value: "05211", text: "潟上市" },
        { text: "大仙市", value: "05212" },
        { text: "北秋田市", value: "05213" },
        { text: "にかほ市", value: "05214" },
        { text: "仙北市", value: "05215" },
        { text: "鹿角郡小坂町", value: "05303" },
        { value: "05327", text: "北秋田郡上小阿仁村" },
        { value: "05346", text: "山本郡藤里町" },
        { text: "山本郡三種町", value: "05348" },
        { text: "山本郡八峰町", value: "05349" },
        { text: "南秋田郡五城目町", value: "05361" },
        { text: "南秋田郡八郎潟町", value: "05363" },
        { text: "南秋田郡井川町", value: "05366" },
        { text: "南秋田郡大潟村", value: "05368" },
        { text: "仙北郡美郷町", value: "05434" },
        { value: "05463", text: "雄勝郡羽後町" },
        { text: "雄勝郡東成瀬村", value: "05464" },
      ],
      text: "秋田県",
      value: "05",
    },
    {
      value: "06",
      text: "山形県",
      city: [
        { text: "山形市", value: "06201" },
        { text: "米沢市", value: "06202" },
        { value: "06203", text: "鶴岡市" },
        { value: "06204", text: "酒田市" },
        { text: "新庄市", value: "06205" },
        { text: "寒河江市", value: "06206" },
        { value: "06207", text: "上山市" },
        { value: "06208", text: "村山市" },
        { text: "長井市", value: "06209" },
        { value: "06210", text: "天童市" },
        { text: "東根市", value: "06211" },
        { value: "06212", text: "尾花沢市" },
        { text: "南陽市", value: "06213" },
        { value: "06301", text: "東村山郡山辺町" },
        { value: "06302", text: "東村山郡中山町" },
        { value: "06321", text: "西村山郡河北町" },
        { text: "西村山郡西川町", value: "06322" },
        { value: "06323", text: "西村山郡朝日町" },
        { text: "西村山郡大江町", value: "06324" },
        { text: "北村山郡大石田町", value: "06341" },
        { value: "06361", text: "最上郡金山町" },
        { value: "06362", text: "最上郡最上町" },
        { value: "06363", text: "最上郡舟形町" },
        { text: "最上郡真室川町", value: "06364" },
        { text: "最上郡大蔵村", value: "06365" },
        { value: "06366", text: "最上郡鮭川村" },
        { value: "06367", text: "最上郡戸沢村" },
        { value: "06381", text: "東置賜郡高畠町" },
        { value: "06382", text: "東置賜郡川西町" },
        { text: "西置賜郡小国町", value: "06401" },
        { text: "西置賜郡白鷹町", value: "06402" },
        { text: "西置賜郡飯豊町", value: "06403" },
        { value: "06426", text: "東田川郡三川町" },
        { value: "06428", text: "東田川郡庄内町" },
        { text: "飽海郡遊佐町", value: "06461" },
      ],
    },
    {
      city: [
        { text: "福島市", value: "07201" },
        { text: "会津若松市", value: "07202" },
        { value: "07203", text: "郡山市" },
        { value: "07204", text: "いわき市" },
        { value: "07205", text: "白河市" },
        { text: "須賀川市", value: "07207" },
        { value: "07208", text: "喜多方市" },
        { text: "相馬市", value: "07209" },
        { text: "二本松市", value: "07210" },
        { value: "07211", text: "田村市" },
        { text: "南相馬市", value: "07212" },
        { text: "伊達市", value: "07213" },
        { value: "07214", text: "本宮市" },
        { value: "07301", text: "伊達郡桑折町" },
        { value: "07303", text: "伊達郡国見町" },
        { value: "07308", text: "伊達郡川俣町" },
        { text: "安達郡大玉村", value: "07322" },
        { text: "岩瀬郡鏡石町", value: "07342" },
        { value: "07344", text: "岩瀬郡天栄村" },
        { text: "南会津郡下郷町", value: "07362" },
        { value: "07364", text: "南会津郡檜枝岐村" },
        { value: "07367", text: "南会津郡只見町" },
        { value: "07368", text: "南会津郡南会津町" },
        { value: "07402", text: "耶麻郡北塩原村" },
        { value: "07405", text: "耶麻郡西会津町" },
        { text: "耶麻郡磐梯町", value: "07407" },
        { value: "07408", text: "耶麻郡猪苗代町" },
        { text: "河沼郡会津坂下町", value: "07421" },
        { value: "07422", text: "河沼郡湯川村" },
        { text: "河沼郡柳津町", value: "07423" },
        { value: "07444", text: "大沼郡三島町" },
        { value: "07445", text: "大沼郡金山町" },
        { value: "07446", text: "大沼郡昭和村" },
        { value: "07447", text: "大沼郡会津美里町" },
        { value: "07461", text: "西白河郡西郷村" },
        { text: "西白河郡泉崎村", value: "07464" },
        { text: "西白河郡中島村", value: "07465" },
        { value: "07466", text: "西白河郡矢吹町" },
        { text: "東白川郡棚倉町", value: "07481" },
        { value: "07482", text: "東白川郡矢祭町" },
        { text: "東白川郡塙町", value: "07483" },
        { text: "東白川郡鮫川村", value: "07484" },
        { text: "石川郡石川町", value: "07501" },
        { text: "石川郡玉川村", value: "07502" },
        { value: "07503", text: "石川郡平田村" },
        { value: "07504", text: "石川郡浅川町" },
        { text: "石川郡古殿町", value: "07505" },
        { text: "田村郡三春町", value: "07521" },
        { value: "07522", text: "田村郡小野町" },
        { text: "双葉郡広野町", value: "07541" },
        { text: "双葉郡楢葉町", value: "07542" },
        { text: "双葉郡富岡町", value: "07543" },
        { text: "双葉郡川内村", value: "07544" },
        { value: "07545", text: "双葉郡大熊町" },
        { value: "07546", text: "双葉郡双葉町" },
        { text: "双葉郡浪江町", value: "07547" },
        { value: "07548", text: "双葉郡葛尾村" },
        { value: "07561", text: "相馬郡新地町" },
        { value: "07564", text: "相馬郡飯舘村" },
      ],
      value: "07",
      text: "福島県",
    },
    {
      city: [
        { text: "水戸市", value: "08201" },
        { value: "08202", text: "日立市" },
        { text: "土浦市", value: "08203" },
        { value: "08204", text: "古河市" },
        { text: "石岡市", value: "08205" },
        { text: "結城市", value: "08207" },
        { value: "08208", text: "龍ケ崎市" },
        { text: "下妻市", value: "08210" },
        { value: "08211", text: "常総市" },
        { value: "08212", text: "常陸太田市" },
        { text: "高萩市", value: "08214" },
        { text: "北茨城市", value: "08215" },
        { text: "笠間市", value: "08216" },
        { text: "取手市", value: "08217" },
        { text: "牛久市", value: "08219" },
        { value: "08220", text: "つくば市" },
        { value: "08221", text: "ひたちなか市" },
        { text: "鹿嶋市", value: "08222" },
        { value: "08223", text: "潮来市" },
        { value: "08224", text: "守谷市" },
        { value: "08225", text: "常陸大宮市" },
        { value: "08226", text: "那珂市" },
        { text: "筑西市", value: "08227" },
        { value: "08228", text: "坂東市" },
        { text: "稲敷市", value: "08229" },
        { text: "かすみがうら市", value: "08230" },
        { text: "桜川市", value: "08231" },
        { value: "08232", text: "神栖市" },
        { text: "行方市", value: "08233" },
        { value: "08234", text: "鉾田市" },
        { text: "つくばみらい市", value: "08235" },
        { value: "08236", text: "小美玉市" },
        { value: "08302", text: "東茨城郡茨城町" },
        { text: "東茨城郡大洗町", value: "08309" },
        { value: "08310", text: "東茨城郡城里町" },
        { value: "08341", text: "那珂郡東海村" },
        { value: "08364", text: "久慈郡大子町" },
        { text: "稲敷郡美浦村", value: "08442" },
        { value: "08443", text: "稲敷郡阿見町" },
        { text: "稲敷郡河内町", value: "08447" },
        { value: "08521", text: "結城郡八千代町" },
        { text: "猿島郡五霞町", value: "08542" },
        { text: "猿島郡境町", value: "08546" },
        { value: "08564", text: "北相馬郡利根町" },
      ],
      value: "08",
      text: "茨城県",
    },
    {
      city: [
        { text: "宇都宮市", value: "09201" },
        { text: "足利市", value: "09202" },
        { text: "栃木市", value: "09203" },
        { value: "09204", text: "佐野市" },
        { value: "09205", text: "鹿沼市" },
        { text: "日光市", value: "09206" },
        { text: "小山市", value: "09208" },
        { value: "09209", text: "真岡市" },
        { value: "09210", text: "大田原市" },
        { value: "09211", text: "矢板市" },
        { value: "09213", text: "那須塩原市" },
        { value: "09214", text: "さくら市" },
        { value: "09215", text: "那須烏山市" },
        { text: "下野市", value: "09216" },
        { text: "河内郡上三川町", value: "09301" },
        { value: "09342", text: "芳賀郡益子町" },
        { text: "芳賀郡茂木町", value: "09343" },
        { value: "09344", text: "芳賀郡市貝町" },
        { text: "芳賀郡芳賀町", value: "09345" },
        { text: "下都賀郡壬生町", value: "09361" },
        { value: "09364", text: "下都賀郡野木町" },
        { value: "09384", text: "塩谷郡塩谷町" },
        { value: "09386", text: "塩谷郡高根沢町" },
        { text: "那須郡那須町", value: "09407" },
        { text: "那須郡那珂川町", value: "09411" },
      ],
      value: "09",
      text: "栃木県",
    },
    {
      value: "10",
      text: "群馬県",
      city: [
        { value: "10201", text: "前橋市" },
        { text: "高崎市", value: "10202" },
        { text: "桐生市", value: "10203" },
        { value: "10204", text: "伊勢崎市" },
        { value: "10205", text: "太田市" },
        { value: "10206", text: "沼田市" },
        { text: "館林市", value: "10207" },
        { text: "渋川市", value: "10208" },
        { text: "藤岡市", value: "10209" },
        { text: "富岡市", value: "10210" },
        { text: "安中市", value: "10211" },
        { value: "10212", text: "みどり市" },
        { value: "10344", text: "北群馬郡榛東村" },
        { value: "10345", text: "北群馬郡吉岡町" },
        { text: "多野郡上野村", value: "10366" },
        { text: "多野郡神流町", value: "10367" },
        { text: "甘楽郡下仁田町", value: "10382" },
        { value: "10383", text: "甘楽郡南牧村" },
        { text: "甘楽郡甘楽町", value: "10384" },
        { text: "吾妻郡中之条町", value: "10421" },
        { text: "吾妻郡長野原町", value: "10424" },
        { value: "10425", text: "吾妻郡嬬恋村" },
        { text: "吾妻郡草津町", value: "10426" },
        { text: "吾妻郡高山村", value: "10428" },
        { value: "10429", text: "吾妻郡東吾妻町" },
        { text: "利根郡片品村", value: "10443" },
        { value: "10444", text: "利根郡川場村" },
        { value: "10448", text: "利根郡昭和村" },
        { text: "利根郡みなかみ町", value: "10449" },
        { value: "10464", text: "佐波郡玉村町" },
        { value: "10521", text: "邑楽郡板倉町" },
        { value: "10522", text: "邑楽郡明和町" },
        { text: "邑楽郡千代田町", value: "10523" },
        { text: "邑楽郡大泉町", value: "10524" },
        { value: "10525", text: "邑楽郡邑楽町" },
      ],
    },
    {
      value: "11",
      text: "埼玉県",
      city: [
        { text: "さいたま市", value: "11100" },
        { text: "さいたま市西区", value: "11101" },
        { value: "11102", text: "さいたま市北区" },
        { value: "11103", text: "さいたま市大宮区" },
        { text: "さいたま市見沼区", value: "11104" },
        { value: "11105", text: "さいたま市中央区" },
        { value: "11106", text: "さいたま市桜区" },
        { text: "さいたま市浦和区", value: "11107" },
        { text: "さいたま市南区", value: "11108" },
        { text: "さいたま市緑区", value: "11109" },
        { value: "11110", text: "さいたま市岩槻区" },
        { value: "11201", text: "川越市" },
        { value: "11202", text: "熊谷市" },
        { text: "川口市", value: "11203" },
        { text: "行田市", value: "11206" },
        { value: "11207", text: "秩父市" },
        { value: "11208", text: "所沢市" },
        { value: "11209", text: "飯能市" },
        { text: "加須市", value: "11210" },
        { text: "本庄市", value: "11211" },
        { text: "東松山市", value: "11212" },
        { text: "春日部市", value: "11214" },
        { text: "狭山市", value: "11215" },
        { value: "11216", text: "羽生市" },
        { value: "11217", text: "鴻巣市" },
        { text: "深谷市", value: "11218" },
        { text: "上尾市", value: "11219" },
        { value: "11221", text: "草加市" },
        { value: "11222", text: "越谷市" },
        { text: "蕨市", value: "11223" },
        { value: "11224", text: "戸田市" },
        { value: "11225", text: "入間市" },
        { text: "朝霞市", value: "11227" },
        { text: "志木市", value: "11228" },
        { value: "11229", text: "和光市" },
        { text: "新座市", value: "11230" },
        { value: "11231", text: "桶川市" },
        { value: "11232", text: "久喜市" },
        { value: "11233", text: "北本市" },
        { value: "11234", text: "八潮市" },
        { text: "富士見市", value: "11235" },
        { text: "三郷市", value: "11237" },
        { text: "蓮田市", value: "11238" },
        { text: "坂戸市", value: "11239" },
        { value: "11240", text: "幸手市" },
        { text: "鶴ヶ島市", value: "11241" },
        { value: "11242", text: "日高市" },
        { value: "11243", text: "吉川市" },
        { text: "ふじみ野市", value: "11245" },
        { value: "11246", text: "白岡市" },
        { text: "北足立郡伊奈町", value: "11301" },
        { value: "11324", text: "入間郡三芳町" },
        { text: "入間郡毛呂山町", value: "11326" },
        { text: "入間郡越生町", value: "11327" },
        { text: "比企郡滑川町", value: "11341" },
        { value: "11342", text: "比企郡嵐山町" },
        { text: "比企郡小川町", value: "11343" },
        { text: "比企郡川島町", value: "11346" },
        { value: "11347", text: "比企郡吉見町" },
        { text: "比企郡鳩山町", value: "11348" },
        { text: "比企郡ときがわ町", value: "11349" },
        { text: "秩父郡横瀬町", value: "11361" },
        { text: "秩父郡皆野町", value: "11362" },
        { value: "11363", text: "秩父郡長瀞町" },
        { value: "11365", text: "秩父郡小鹿野町" },
        { value: "11369", text: "秩父郡東秩父村" },
        { text: "児玉郡美里町", value: "11381" },
        { value: "11383", text: "児玉郡神川町" },
        { text: "児玉郡上里町", value: "11385" },
        { value: "11408", text: "大里郡寄居町" },
        { value: "11442", text: "南埼玉郡宮代町" },
        { text: "北葛飾郡杉戸町", value: "11464" },
        { text: "北葛飾郡松伏町", value: "11465" },
      ],
    },
    {
      value: "12",
      text: "千葉県",
      city: [
        { text: "千葉市", value: "12100" },
        { value: "12101", text: "千葉市中央区" },
        { value: "12102", text: "千葉市花見川区" },
        { value: "12103", text: "千葉市稲毛区" },
        { text: "千葉市若葉区", value: "12104" },
        { text: "千葉市緑区", value: "12105" },
        { value: "12106", text: "千葉市美浜区" },
        { value: "12202", text: "銚子市" },
        { value: "12203", text: "市川市" },
        { value: "12204", text: "船橋市" },
        { value: "12205", text: "館山市" },
        { value: "12206", text: "木更津市" },
        { value: "12207", text: "松戸市" },
        { value: "12208", text: "野田市" },
        { value: "12210", text: "茂原市" },
        { value: "12211", text: "成田市" },
        { value: "12212", text: "佐倉市" },
        { text: "東金市", value: "12213" },
        { text: "旭市", value: "12215" },
        { text: "習志野市", value: "12216" },
        { value: "12217", text: "柏市" },
        { value: "12218", text: "勝浦市" },
        { text: "市原市", value: "12219" },
        { text: "流山市", value: "12220" },
        { text: "八千代市", value: "12221" },
        { value: "12222", text: "我孫子市" },
        { value: "12223", text: "鴨川市" },
        { text: "鎌ケ谷市", value: "12224" },
        { text: "君津市", value: "12225" },
        { value: "12226", text: "富津市" },
        { text: "浦安市", value: "12227" },
        { value: "12228", text: "四街道市" },
        { value: "12229", text: "袖ケ浦市" },
        { value: "12230", text: "八街市" },
        { value: "12231", text: "印西市" },
        { text: "白井市", value: "12232" },
        { text: "富里市", value: "12233" },
        { value: "12234", text: "南房総市" },
        { text: "匝瑳市", value: "12235" },
        { text: "香取市", value: "12236" },
        { value: "12237", text: "山武市" },
        { text: "いすみ市", value: "12238" },
        { value: "12239", text: "大網白里市" },
        { text: "印旛郡酒々井町", value: "12322" },
        { value: "12329", text: "印旛郡栄町" },
        { value: "12342", text: "香取郡神崎町" },
        { text: "香取郡多古町", value: "12347" },
        { text: "香取郡東庄町", value: "12349" },
        { value: "12403", text: "山武郡九十九里町" },
        { value: "12409", text: "山武郡芝山町" },
        { text: "山武郡横芝光町", value: "12410" },
        { text: "長生郡一宮町", value: "12421" },
        { text: "長生郡睦沢町", value: "12422" },
        { text: "長生郡長生村", value: "12423" },
        { text: "長生郡白子町", value: "12424" },
        { value: "12426", text: "長生郡長柄町" },
        { value: "12427", text: "長生郡長南町" },
        { value: "12441", text: "夷隅郡大多喜町" },
        { value: "12443", text: "夷隅郡御宿町" },
        { value: "12463", text: "安房郡鋸南町" },
      ],
    },
    {
      city: [
        { value: "13100", text: "特別区部" },
        { value: "13101", text: "千代田区" },
        { value: "13102", text: "中央区" },
        { text: "港区", value: "13103" },
        { text: "新宿区", value: "13104" },
        { text: "文京区", value: "13105" },
        { value: "13106", text: "台東区" },
        { text: "墨田区", value: "13107" },
        { value: "13108", text: "江東区" },
        { text: "品川区", value: "13109" },
        { value: "13110", text: "目黒区" },
        { value: "13111", text: "大田区" },
        { value: "13112", text: "世田谷区" },
        { value: "13113", text: "渋谷区" },
        { text: "中野区", value: "13114" },
        { text: "杉並区", value: "13115" },
        { value: "13116", text: "豊島区" },
        { text: "北区", value: "13117" },
        { value: "13118", text: "荒川区" },
        { text: "板橋区", value: "13119" },
        { text: "練馬区", value: "13120" },
        { value: "13121", text: "足立区" },
        { value: "13122", text: "葛飾区" },
        { value: "13123", text: "江戸川区" },
        { value: "13201", text: "八王子市" },
        { value: "13202", text: "立川市" },
        { value: "13203", text: "武蔵野市" },
        { text: "三鷹市", value: "13204" },
        { value: "13205", text: "青梅市" },
        { value: "13206", text: "府中市" },
        { text: "昭島市", value: "13207" },
        { value: "13208", text: "調布市" },
        { text: "町田市", value: "13209" },
        { text: "小金井市", value: "13210" },
        { text: "小平市", value: "13211" },
        { value: "13212", text: "日野市" },
        { text: "東村山市", value: "13213" },
        { value: "13214", text: "国分寺市" },
        { text: "国立市", value: "13215" },
        { text: "福生市", value: "13218" },
        { text: "狛江市", value: "13219" },
        { text: "東大和市", value: "13220" },
        { value: "13221", text: "清瀬市" },
        { value: "13222", text: "東久留米市" },
        { value: "13223", text: "武蔵村山市" },
        { value: "13224", text: "多摩市" },
        { value: "13225", text: "稲城市" },
        { value: "13227", text: "羽村市" },
        { text: "あきる野市", value: "13228" },
        { text: "西東京市", value: "13229" },
        { text: "西多摩郡瑞穂町", value: "13303" },
        { value: "13305", text: "西多摩郡日の出町" },
        { text: "西多摩郡檜原村", value: "13307" },
        { value: "13308", text: "西多摩郡奥多摩町" },
        { text: "大島支庁大島町", value: "13361" },
        { text: "大島支庁利島村", value: "13362" },
        { value: "13363", text: "大島支庁新島村" },
        { text: "大島支庁神津島村", value: "13364" },
        { text: "三宅支庁三宅村", value: "13381" },
        { value: "13382", text: "三宅支庁御蔵島村" },
        { text: "八丈支庁八丈町", value: "13401" },
        { value: "13402", text: "八丈支庁青ヶ島村" },
        { value: "13421", text: "小笠原支庁小笠原村" },
      ],
      value: "13",
      text: "東京都",
    },
    {
      city: [
        { value: "14100", text: "横浜市" },
        { text: "横浜市鶴見区", value: "14101" },
        { text: "横浜市神奈川区", value: "14102" },
        { value: "14103", text: "横浜市西区" },
        { value: "14104", text: "横浜市中区" },
        { value: "14105", text: "横浜市南区" },
        { value: "14106", text: "横浜市保土ケ谷区" },
        { text: "横浜市磯子区", value: "14107" },
        { text: "横浜市金沢区", value: "14108" },
        { text: "横浜市港北区", value: "14109" },
        { text: "横浜市戸塚区", value: "14110" },
        { text: "横浜市港南区", value: "14111" },
        { value: "14112", text: "横浜市旭区" },
        { value: "14113", text: "横浜市緑区" },
        { value: "14114", text: "横浜市瀬谷区" },
        { value: "14115", text: "横浜市栄区" },
        { text: "横浜市泉区", value: "14116" },
        { text: "横浜市青葉区", value: "14117" },
        { text: "横浜市都筑区", value: "14118" },
        { text: "川崎市", value: "14130" },
        { value: "14131", text: "川崎市川崎区" },
        { text: "川崎市幸区", value: "14132" },
        { text: "川崎市中原区", value: "14133" },
        { value: "14134", text: "川崎市高津区" },
        { value: "14135", text: "川崎市多摩区" },
        { value: "14136", text: "川崎市宮前区" },
        { text: "川崎市麻生区", value: "14137" },
        { value: "14150", text: "相模原市" },
        { value: "14151", text: "相模原市緑区" },
        { value: "14152", text: "相模原市中央区" },
        { value: "14153", text: "相模原市南区" },
        { value: "14201", text: "横須賀市" },
        { value: "14203", text: "平塚市" },
        { text: "鎌倉市", value: "14204" },
        { value: "14205", text: "藤沢市" },
        { value: "14206", text: "小田原市" },
        { value: "14207", text: "茅ヶ崎市" },
        { value: "14208", text: "逗子市" },
        { value: "14210", text: "三浦市" },
        { text: "秦野市", value: "14211" },
        { value: "14212", text: "厚木市" },
        { value: "14213", text: "大和市" },
        { text: "伊勢原市", value: "14214" },
        { value: "14215", text: "海老名市" },
        { value: "14216", text: "座間市" },
        { value: "14217", text: "南足柄市" },
        { value: "14218", text: "綾瀬市" },
        { value: "14301", text: "三浦郡葉山町" },
        { value: "14321", text: "高座郡寒川町" },
        { value: "14341", text: "中郡大磯町" },
        { text: "中郡二宮町", value: "14342" },
        { text: "足柄上郡中井町", value: "14361" },
        { value: "14362", text: "足柄上郡大井町" },
        { text: "足柄上郡松田町", value: "14363" },
        { text: "足柄上郡山北町", value: "14364" },
        { value: "14366", text: "足柄上郡開成町" },
        { value: "14382", text: "足柄下郡箱根町" },
        { value: "14383", text: "足柄下郡真鶴町" },
        { value: "14384", text: "足柄下郡湯河原町" },
        { value: "14401", text: "愛甲郡愛川町" },
        { text: "愛甲郡清川村", value: "14402" },
      ],
      value: "14",
      text: "神奈川県",
    },
    {
      city: [
        { value: "15100", text: "新潟市" },
        { text: "新潟市北区", value: "15101" },
        { text: "新潟市東区", value: "15102" },
        { value: "15103", text: "新潟市中央区" },
        { value: "15104", text: "新潟市江南区" },
        { value: "15105", text: "新潟市秋葉区" },
        { value: "15106", text: "新潟市南区" },
        { text: "新潟市西区", value: "15107" },
        { text: "新潟市西蒲区", value: "15108" },
        { value: "15202", text: "長岡市" },
        { text: "三条市", value: "15204" },
        { text: "柏崎市", value: "15205" },
        { text: "新発田市", value: "15206" },
        { text: "小千谷市", value: "15208" },
        { value: "15209", text: "加茂市" },
        { text: "十日町市", value: "15210" },
        { value: "15211", text: "見附市" },
        { value: "15212", text: "村上市" },
        { value: "15213", text: "燕市" },
        { text: "糸魚川市", value: "15216" },
        { text: "妙高市", value: "15217" },
        { text: "五泉市", value: "15218" },
        { value: "15222", text: "上越市" },
        { value: "15223", text: "阿賀野市" },
        { text: "佐渡市", value: "15224" },
        { text: "魚沼市", value: "15225" },
        { value: "15226", text: "南魚沼市" },
        { value: "15227", text: "胎内市" },
        { text: "北蒲原郡聖籠町", value: "15307" },
        { text: "西蒲原郡弥彦村", value: "15342" },
        { text: "南蒲原郡田上町", value: "15361" },
        { text: "東蒲原郡阿賀町", value: "15385" },
        { value: "15405", text: "三島郡出雲崎町" },
        { value: "15461", text: "南魚沼郡湯沢町" },
        { text: "中魚沼郡津南町", value: "15482" },
        { text: "刈羽郡刈羽村", value: "15504" },
        { value: "15581", text: "岩船郡関川村" },
        { value: "15586", text: "岩船郡粟島浦村" },
      ],
      value: "15",
      text: "新潟県",
    },
    {
      city: [
        { value: "16201", text: "富山市" },
        { value: "16202", text: "高岡市" },
        { value: "16204", text: "魚津市" },
        { text: "氷見市", value: "16205" },
        { text: "滑川市", value: "16206" },
        { value: "16207", text: "黒部市" },
        { text: "砺波市", value: "16208" },
        { text: "小矢部市", value: "16209" },
        { value: "16210", text: "南砺市" },
        { value: "16211", text: "射水市" },
        { text: "中新川郡舟橋村", value: "16321" },
        { text: "中新川郡上市町", value: "16322" },
        { text: "中新川郡立山町", value: "16323" },
        { text: "下新川郡入善町", value: "16342" },
        { value: "16343", text: "下新川郡朝日町" },
      ],
      text: "富山県",
      value: "16",
    },
    {
      city: [
        { text: "金沢市", value: "17201" },
        { text: "七尾市", value: "17202" },
        { text: "小松市", value: "17203" },
        { value: "17204", text: "輪島市" },
        { text: "珠洲市", value: "17205" },
        { value: "17206", text: "加賀市" },
        { value: "17207", text: "羽咋市" },
        { value: "17209", text: "かほく市" },
        { text: "白山市", value: "17210" },
        { value: "17211", text: "能美市" },
        { text: "野々市市", value: "17212" },
        { value: "17324", text: "能美郡川北町" },
        { text: "河北郡津幡町", value: "17361" },
        { value: "17365", text: "河北郡内灘町" },
        { text: "羽咋郡志賀町", value: "17384" },
        { value: "17386", text: "羽咋郡宝達志水町" },
        { text: "鹿島郡中能登町", value: "17407" },
        { text: "鳳珠郡穴水町", value: "17461" },
        { text: "鳳珠郡能登町", value: "17463" },
      ],
      text: "石川県",
      value: "17",
    },
    {
      city: [
        { text: "福井市", value: "18201" },
        { value: "18202", text: "敦賀市" },
        { value: "18204", text: "小浜市" },
        { value: "18205", text: "大野市" },
        { value: "18206", text: "勝山市" },
        { text: "鯖江市", value: "18207" },
        { text: "あわら市", value: "18208" },
        { value: "18209", text: "越前市" },
        { value: "18210", text: "坂井市" },
        { value: "18322", text: "吉田郡永平寺町" },
        { text: "今立郡池田町", value: "18382" },
        { value: "18404", text: "南条郡南越前町" },
        { text: "丹生郡越前町", value: "18423" },
        { text: "三方郡美浜町", value: "18442" },
        { text: "大飯郡高浜町", value: "18481" },
        { value: "18483", text: "大飯郡おおい町" },
        { value: "18501", text: "三方上中郡若狭町" },
      ],
      text: "福井県",
      value: "18",
    },
    {
      text: "山梨県",
      value: "19",
      city: [
        { value: "19201", text: "甲府市" },
        { value: "19202", text: "富士吉田市" },
        { value: "19204", text: "都留市" },
        { value: "19205", text: "山梨市" },
        { value: "19206", text: "大月市" },
        { text: "韮崎市", value: "19207" },
        { value: "19208", text: "南アルプス市" },
        { value: "19209", text: "北杜市" },
        { text: "甲斐市", value: "19210" },
        { text: "笛吹市", value: "19211" },
        { text: "上野原市", value: "19212" },
        { value: "19213", text: "甲州市" },
        { text: "中央市", value: "19214" },
        { value: "19346", text: "西八代郡市川三郷町" },
        { value: "19364", text: "南巨摩郡早川町" },
        { text: "南巨摩郡身延町", value: "19365" },
        { text: "南巨摩郡南部町", value: "19366" },
        { text: "南巨摩郡富士川町", value: "19368" },
        { value: "19384", text: "中巨摩郡昭和町" },
        { value: "19422", text: "南都留郡道志村" },
        { value: "19423", text: "南都留郡西桂町" },
        { text: "南都留郡忍野村", value: "19424" },
        { value: "19425", text: "南都留郡山中湖村" },
        { value: "19429", text: "南都留郡鳴沢村" },
        { text: "南都留郡富士河口湖町", value: "19430" },
        { text: "北都留郡小菅村", value: "19442" },
        { value: "19443", text: "北都留郡丹波山村" },
      ],
    },
    {
      city: [
        { text: "長野市", value: "20201" },
        { text: "松本市", value: "20202" },
        { text: "上田市", value: "20203" },
        { value: "20204", text: "岡谷市" },
        { text: "飯田市", value: "20205" },
        { value: "20206", text: "諏訪市" },
        { value: "20207", text: "須坂市" },
        { text: "小諸市", value: "20208" },
        { text: "伊那市", value: "20209" },
        { value: "20210", text: "駒ヶ根市" },
        { text: "中野市", value: "20211" },
        { value: "20212", text: "大町市" },
        { value: "20213", text: "飯山市" },
        { value: "20214", text: "茅野市" },
        { value: "20215", text: "塩尻市" },
        { value: "20217", text: "佐久市" },
        { value: "20218", text: "千曲市" },
        { text: "東御市", value: "20219" },
        { text: "安曇野市", value: "20220" },
        { text: "南佐久郡小海町", value: "20303" },
        { value: "20304", text: "南佐久郡川上村" },
        { value: "20305", text: "南佐久郡南牧村" },
        { text: "南佐久郡南相木村", value: "20306" },
        { text: "南佐久郡北相木村", value: "20307" },
        { value: "20309", text: "南佐久郡佐久穂町" },
        { value: "20321", text: "北佐久郡軽井沢町" },
        { value: "20323", text: "北佐久郡御代田町" },
        { text: "北佐久郡立科町", value: "20324" },
        { text: "小県郡青木村", value: "20349" },
        { text: "小県郡長和町", value: "20350" },
        { value: "20361", text: "諏訪郡下諏訪町" },
        { value: "20362", text: "諏訪郡富士見町" },
        { value: "20363", text: "諏訪郡原村" },
        { text: "上伊那郡辰野町", value: "20382" },
        { text: "上伊那郡箕輪町", value: "20383" },
        { value: "20384", text: "上伊那郡飯島町" },
        { value: "20385", text: "上伊那郡南箕輪村" },
        { text: "上伊那郡中川村", value: "20386" },
        { value: "20388", text: "上伊那郡宮田村" },
        { value: "20402", text: "下伊那郡松川町" },
        { text: "下伊那郡高森町", value: "20403" },
        { text: "下伊那郡阿南町", value: "20404" },
        { text: "下伊那郡阿智村", value: "20407" },
        { value: "20409", text: "下伊那郡平谷村" },
        { text: "下伊那郡根羽村", value: "20410" },
        { text: "下伊那郡下條村", value: "20411" },
        { text: "下伊那郡売木村", value: "20412" },
        { text: "下伊那郡天龍村", value: "20413" },
        { text: "下伊那郡泰阜村", value: "20414" },
        { value: "20415", text: "下伊那郡喬木村" },
        { text: "下伊那郡豊丘村", value: "20416" },
        { value: "20417", text: "下伊那郡大鹿村" },
        { text: "木曽郡上松町", value: "20422" },
        { text: "木曽郡南木曽町", value: "20423" },
        { text: "木曽郡木祖村", value: "20425" },
        { text: "木曽郡王滝村", value: "20429" },
        { text: "木曽郡大桑村", value: "20430" },
        { text: "木曽郡木曽町", value: "20432" },
        { value: "20446", text: "東筑摩郡麻績村" },
        { value: "20448", text: "東筑摩郡生坂村" },
        { value: "20450", text: "東筑摩郡山形村" },
        { value: "20451", text: "東筑摩郡朝日村" },
        { value: "20452", text: "東筑摩郡筑北村" },
        { text: "北安曇郡池田町", value: "20481" },
        { text: "北安曇郡松川村", value: "20482" },
        { value: "20485", text: "北安曇郡白馬村" },
        { text: "北安曇郡小谷村", value: "20486" },
        { text: "埴科郡坂城町", value: "20521" },
        { text: "上高井郡小布施町", value: "20541" },
        { value: "20543", text: "上高井郡高山村" },
        { value: "20561", text: "下高井郡山ノ内町" },
        { value: "20562", text: "下高井郡木島平村" },
        { text: "下高井郡野沢温泉村", value: "20563" },
        { value: "20583", text: "上水内郡信濃町" },
        { value: "20588", text: "上水内郡小川村" },
        { value: "20590", text: "上水内郡飯綱町" },
        { text: "下水内郡栄村", value: "20602" },
      ],
      value: "20",
      text: "長野県",
    },
    {
      city: [
        { value: "21201", text: "岐阜市" },
        { text: "大垣市", value: "21202" },
        { value: "21203", text: "高山市" },
        { text: "多治見市", value: "21204" },
        { text: "関市", value: "21205" },
        { value: "21206", text: "中津川市" },
        { text: "美濃市", value: "21207" },
        { value: "21208", text: "瑞浪市" },
        { text: "羽島市", value: "21209" },
        { value: "21210", text: "恵那市" },
        { text: "美濃加茂市", value: "21211" },
        { value: "21212", text: "土岐市" },
        { text: "各務原市", value: "21213" },
        { value: "21214", text: "可児市" },
        { text: "山県市", value: "21215" },
        { value: "21216", text: "瑞穂市" },
        { value: "21217", text: "飛騨市" },
        { value: "21218", text: "本巣市" },
        { value: "21219", text: "郡上市" },
        { text: "下呂市", value: "21220" },
        { value: "21221", text: "海津市" },
        { value: "21302", text: "羽島郡岐南町" },
        { text: "羽島郡笠松町", value: "21303" },
        { text: "養老郡養老町", value: "21341" },
        { text: "不破郡垂井町", value: "21361" },
        { value: "21362", text: "不破郡関ケ原町" },
        { text: "安八郡神戸町", value: "21381" },
        { text: "安八郡輪之内町", value: "21382" },
        { value: "21383", text: "安八郡安八町" },
        { text: "揖斐郡揖斐川町", value: "21401" },
        { value: "21403", text: "揖斐郡大野町" },
        { text: "揖斐郡池田町", value: "21404" },
        { value: "21421", text: "本巣郡北方町" },
        { value: "21501", text: "加茂郡坂祝町" },
        { text: "加茂郡富加町", value: "21502" },
        { text: "加茂郡川辺町", value: "21503" },
        { text: "加茂郡七宗町", value: "21504" },
        { value: "21505", text: "加茂郡八百津町" },
        { text: "加茂郡白川町", value: "21506" },
        { value: "21507", text: "加茂郡東白川村" },
        { value: "21521", text: "可児郡御嵩町" },
        { text: "大野郡白川村", value: "21604" },
      ],
      value: "21",
      text: "岐阜県",
    },
    {
      value: "22",
      text: "静岡県",
      city: [
        { text: "静岡市", value: "22100" },
        { value: "22101", text: "静岡市葵区" },
        { text: "静岡市駿河区", value: "22102" },
        { text: "静岡市清水区", value: "22103" },
        { text: "浜松市", value: "22130" },
        { text: "浜松市中区", value: "22131" },
        { text: "浜松市東区", value: "22132" },
        { text: "浜松市西区", value: "22133" },
        { value: "22134", text: "浜松市南区" },
        { value: "22135", text: "浜松市北区" },
        { text: "浜松市浜北区", value: "22136" },
        { text: "浜松市天竜区", value: "22137" },
        { text: "沼津市", value: "22203" },
        { value: "22205", text: "熱海市" },
        { value: "22206", text: "三島市" },
        { value: "22207", text: "富士宮市" },
        { value: "22208", text: "伊東市" },
        { text: "島田市", value: "22209" },
        { value: "22210", text: "富士市" },
        { value: "22211", text: "磐田市" },
        { text: "焼津市", value: "22212" },
        { text: "掛川市", value: "22213" },
        { text: "藤枝市", value: "22214" },
        { value: "22215", text: "御殿場市" },
        { text: "袋井市", value: "22216" },
        { text: "下田市", value: "22219" },
        { text: "裾野市", value: "22220" },
        { text: "湖西市", value: "22221" },
        { value: "22222", text: "伊豆市" },
        { value: "22223", text: "御前崎市" },
        { text: "菊川市", value: "22224" },
        { text: "伊豆の国市", value: "22225" },
        { value: "22226", text: "牧之原市" },
        { text: "賀茂郡東伊豆町", value: "22301" },
        { text: "賀茂郡河津町", value: "22302" },
        { text: "賀茂郡南伊豆町", value: "22304" },
        { text: "賀茂郡松崎町", value: "22305" },
        { text: "賀茂郡西伊豆町", value: "22306" },
        { text: "田方郡函南町", value: "22325" },
        { value: "22341", text: "駿東郡清水町" },
        { value: "22342", text: "駿東郡長泉町" },
        { value: "22344", text: "駿東郡小山町" },
        { value: "22424", text: "榛原郡吉田町" },
        { value: "22429", text: "榛原郡川根本町" },
        { value: "22461", text: "周智郡森町" },
      ],
    },
    {
      city: [
        { text: "名古屋市", value: "23100" },
        { text: "名古屋市千種区", value: "23101" },
        { text: "名古屋市東区", value: "23102" },
        { value: "23103", text: "名古屋市北区" },
        { value: "23104", text: "名古屋市西区" },
        { text: "名古屋市中村区", value: "23105" },
        { text: "名古屋市中区", value: "23106" },
        { text: "名古屋市昭和区", value: "23107" },
        { value: "23108", text: "名古屋市瑞穂区" },
        { value: "23109", text: "名古屋市熱田区" },
        { value: "23110", text: "名古屋市中川区" },
        { text: "名古屋市港区", value: "23111" },
        { text: "名古屋市南区", value: "23112" },
        { text: "名古屋市守山区", value: "23113" },
        { value: "23114", text: "名古屋市緑区" },
        { text: "名古屋市名東区", value: "23115" },
        { value: "23116", text: "名古屋市天白区" },
        { value: "23201", text: "豊橋市" },
        { text: "岡崎市", value: "23202" },
        { value: "23203", text: "一宮市" },
        { text: "瀬戸市", value: "23204" },
        { value: "23205", text: "半田市" },
        { text: "春日井市", value: "23206" },
        { value: "23207", text: "豊川市" },
        { text: "津島市", value: "23208" },
        { value: "23209", text: "碧南市" },
        { text: "刈谷市", value: "23210" },
        { value: "23211", text: "豊田市" },
        { text: "安城市", value: "23212" },
        { text: "西尾市", value: "23213" },
        { value: "23214", text: "蒲郡市" },
        { value: "23215", text: "犬山市" },
        { value: "23216", text: "常滑市" },
        { text: "江南市", value: "23217" },
        { text: "小牧市", value: "23219" },
        { value: "23220", text: "稲沢市" },
        { text: "新城市", value: "23221" },
        { value: "23222", text: "東海市" },
        { text: "大府市", value: "23223" },
        { text: "知多市", value: "23224" },
        { text: "知立市", value: "23225" },
        { value: "23226", text: "尾張旭市" },
        { value: "23227", text: "高浜市" },
        { value: "23228", text: "岩倉市" },
        { text: "豊明市", value: "23229" },
        { text: "日進市", value: "23230" },
        { text: "田原市", value: "23231" },
        { text: "愛西市", value: "23232" },
        { value: "23233", text: "清須市" },
        { text: "北名古屋市", value: "23234" },
        { value: "23235", text: "弥富市" },
        { text: "みよし市", value: "23236" },
        { text: "あま市", value: "23237" },
        { value: "23238", text: "長久手市" },
        { value: "23302", text: "愛知郡東郷町" },
        { value: "23342", text: "西春日井郡豊山町" },
        { value: "23361", text: "丹羽郡大口町" },
        { value: "23362", text: "丹羽郡扶桑町" },
        { value: "23424", text: "海部郡大治町" },
        { text: "海部郡蟹江町", value: "23425" },
        { value: "23427", text: "海部郡飛島村" },
        { text: "知多郡阿久比町", value: "23441" },
        { text: "知多郡東浦町", value: "23442" },
        { text: "知多郡南知多町", value: "23445" },
        { value: "23446", text: "知多郡美浜町" },
        { value: "23447", text: "知多郡武豊町" },
        { text: "額田郡幸田町", value: "23501" },
        { text: "北設楽郡設楽町", value: "23561" },
        { text: "北設楽郡東栄町", value: "23562" },
        { value: "23563", text: "北設楽郡豊根村" },
      ],
      text: "愛知県",
      value: "23",
    },
    {
      city: [
        { value: "24201", text: "津市" },
        { value: "24202", text: "四日市市" },
        { value: "24203", text: "伊勢市" },
        { text: "松阪市", value: "24204" },
        { value: "24205", text: "桑名市" },
        { text: "鈴鹿市", value: "24207" },
        { value: "24208", text: "名張市" },
        { value: "24209", text: "尾鷲市" },
        { value: "24210", text: "亀山市" },
        { text: "鳥羽市", value: "24211" },
        { text: "熊野市", value: "24212" },
        { text: "いなべ市", value: "24214" },
        { text: "志摩市", value: "24215" },
        { text: "伊賀市", value: "24216" },
        { text: "桑名郡木曽岬町", value: "24303" },
        { text: "員弁郡東員町", value: "24324" },
        { text: "三重郡菰野町", value: "24341" },
        { value: "24343", text: "三重郡朝日町" },
        { value: "24344", text: "三重郡川越町" },
        { value: "24441", text: "多気郡多気町" },
        { text: "多気郡明和町", value: "24442" },
        { value: "24443", text: "多気郡大台町" },
        { text: "度会郡玉城町", value: "24461" },
        { value: "24470", text: "度会郡度会町" },
        { value: "24471", text: "度会郡大紀町" },
        { value: "24472", text: "度会郡南伊勢町" },
        { value: "24543", text: "北牟婁郡紀北町" },
        { value: "24561", text: "南牟婁郡御浜町" },
        { text: "南牟婁郡紀宝町", value: "24562" },
      ],
      text: "三重県",
      value: "24",
    },
    {
      value: "25",
      text: "滋賀県",
      city: [
        { text: "大津市", value: "25201" },
        { text: "彦根市", value: "25202" },
        { text: "長浜市", value: "25203" },
        { text: "近江八幡市", value: "25204" },
        { text: "草津市", value: "25206" },
        { text: "守山市", value: "25207" },
        { value: "25208", text: "栗東市" },
        { value: "25209", text: "甲賀市" },
        { value: "25210", text: "野洲市" },
        { value: "25211", text: "湖南市" },
        { value: "25212", text: "高島市" },
        { text: "東近江市", value: "25213" },
        { value: "25214", text: "米原市" },
        { text: "蒲生郡日野町", value: "25383" },
        { text: "蒲生郡竜王町", value: "25384" },
        { value: "25425", text: "愛知郡愛荘町" },
        { value: "25441", text: "犬上郡豊郷町" },
        { text: "犬上郡甲良町", value: "25442" },
        { value: "25443", text: "犬上郡多賀町" },
      ],
    },
    {
      city: [
        { text: "京都市", value: "26100" },
        { text: "京都市北区", value: "26101" },
        { value: "26102", text: "京都市上京区" },
        { value: "26103", text: "京都市左京区" },
        { text: "京都市中京区", value: "26104" },
        { text: "京都市東山区", value: "26105" },
        { value: "26106", text: "京都市下京区" },
        { text: "京都市南区", value: "26107" },
        { value: "26108", text: "京都市右京区" },
        { value: "26109", text: "京都市伏見区" },
        { text: "京都市山科区", value: "26110" },
        { value: "26111", text: "京都市西京区" },
        { value: "26201", text: "福知山市" },
        { text: "舞鶴市", value: "26202" },
        { text: "綾部市", value: "26203" },
        { value: "26204", text: "宇治市" },
        { value: "26205", text: "宮津市" },
        { value: "26206", text: "亀岡市" },
        { text: "城陽市", value: "26207" },
        { value: "26208", text: "向日市" },
        { text: "長岡京市", value: "26209" },
        { value: "26210", text: "八幡市" },
        { text: "京田辺市", value: "26211" },
        { value: "26212", text: "京丹後市" },
        { value: "26213", text: "南丹市" },
        { text: "木津川市", value: "26214" },
        { text: "乙訓郡大山崎町", value: "26303" },
        { value: "26322", text: "久世郡久御山町" },
        { value: "26343", text: "綴喜郡井手町" },
        { text: "綴喜郡宇治田原町", value: "26344" },
        { text: "相楽郡笠置町", value: "26364" },
        { text: "相楽郡和束町", value: "26365" },
        { value: "26366", text: "相楽郡精華町" },
        { value: "26367", text: "相楽郡南山城村" },
        { text: "船井郡京丹波町", value: "26407" },
        { value: "26463", text: "与謝郡伊根町" },
        { text: "与謝郡与謝野町", value: "26465" },
      ],
      text: "京都府",
      value: "26",
    },
    {
      city: [
        { value: "27100", text: "大阪市" },
        { value: "27102", text: "大阪市都島区" },
        { text: "大阪市福島区", value: "27103" },
        { text: "大阪市此花区", value: "27104" },
        { value: "27106", text: "大阪市西区" },
        { value: "27107", text: "大阪市港区" },
        { text: "大阪市大正区", value: "27108" },
        { text: "大阪市天王寺区", value: "27109" },
        { value: "27111", text: "大阪市浪速区" },
        { text: "大阪市西淀川区", value: "27113" },
        { text: "大阪市東淀川区", value: "27114" },
        { value: "27115", text: "大阪市東成区" },
        { text: "大阪市生野区", value: "27116" },
        { value: "27117", text: "大阪市旭区" },
        { text: "大阪市城東区", value: "27118" },
        { text: "大阪市阿倍野区", value: "27119" },
        { text: "大阪市住吉区", value: "27120" },
        { text: "大阪市東住吉区", value: "27121" },
        { value: "27122", text: "大阪市西成区" },
        { text: "大阪市淀川区", value: "27123" },
        { text: "大阪市鶴見区", value: "27124" },
        { text: "大阪市住之江区", value: "27125" },
        { text: "大阪市平野区", value: "27126" },
        { text: "大阪市北区", value: "27127" },
        { text: "大阪市中央区", value: "27128" },
        { text: "堺市", value: "27140" },
        { text: "堺市堺区", value: "27141" },
        { value: "27142", text: "堺市中区" },
        { text: "堺市東区", value: "27143" },
        { value: "27144", text: "堺市西区" },
        { text: "堺市南区", value: "27145" },
        { text: "堺市北区", value: "27146" },
        { value: "27147", text: "堺市美原区" },
        { text: "岸和田市", value: "27202" },
        { text: "豊中市", value: "27203" },
        { text: "池田市", value: "27204" },
        { text: "吹田市", value: "27205" },
        { value: "27206", text: "泉大津市" },
        { value: "27207", text: "高槻市" },
        { value: "27208", text: "貝塚市" },
        { text: "守口市", value: "27209" },
        { value: "27210", text: "枚方市" },
        { value: "27211", text: "茨木市" },
        { value: "27212", text: "八尾市" },
        { text: "泉佐野市", value: "27213" },
        { text: "富田林市", value: "27214" },
        { text: "寝屋川市", value: "27215" },
        { text: "河内長野市", value: "27216" },
        { text: "松原市", value: "27217" },
        { value: "27218", text: "大東市" },
        { text: "和泉市", value: "27219" },
        { text: "箕面市", value: "27220" },
        { text: "柏原市", value: "27221" },
        { text: "羽曳野市", value: "27222" },
        { value: "27223", text: "門真市" },
        { value: "27224", text: "摂津市" },
        { value: "27225", text: "高石市" },
        { value: "27226", text: "藤井寺市" },
        { value: "27227", text: "東大阪市" },
        { text: "泉南市", value: "27228" },
        { value: "27229", text: "四條畷市" },
        { text: "交野市", value: "27230" },
        { text: "大阪狭山市", value: "27231" },
        { value: "27232", text: "阪南市" },
        { text: "三島郡島本町", value: "27301" },
        { text: "豊能郡豊能町", value: "27321" },
        { value: "27322", text: "豊能郡能勢町" },
        { value: "27341", text: "泉北郡忠岡町" },
        { text: "泉南郡熊取町", value: "27361" },
        { value: "27362", text: "泉南郡田尻町" },
        { value: "27366", text: "泉南郡岬町" },
        { text: "南河内郡太子町", value: "27381" },
        { value: "27382", text: "南河内郡河南町" },
        { value: "27383", text: "南河内郡千早赤阪村" },
      ],
      text: "大阪府",
      value: "27",
    },
    {
      city: [
        { text: "神戸市", value: "28100" },
        { value: "28101", text: "神戸市東灘区" },
        { value: "28102", text: "神戸市灘区" },
        { value: "28105", text: "神戸市兵庫区" },
        { value: "28106", text: "神戸市長田区" },
        { text: "神戸市須磨区", value: "28107" },
        { text: "神戸市垂水区", value: "28108" },
        { value: "28109", text: "神戸市北区" },
        { value: "28110", text: "神戸市中央区" },
        { value: "28111", text: "神戸市西区" },
        { text: "姫路市", value: "28201" },
        { text: "尼崎市", value: "28202" },
        { value: "28203", text: "明石市" },
        { value: "28204", text: "西宮市" },
        { value: "28205", text: "洲本市" },
        { value: "28206", text: "芦屋市" },
        { value: "28207", text: "伊丹市" },
        { value: "28208", text: "相生市" },
        { value: "28209", text: "豊岡市" },
        { value: "28210", text: "加古川市" },
        { text: "赤穂市", value: "28212" },
        { value: "28213", text: "西脇市" },
        { text: "宝塚市", value: "28214" },
        { text: "三木市", value: "28215" },
        { value: "28216", text: "高砂市" },
        { text: "川西市", value: "28217" },
        { value: "28218", text: "小野市" },
        { text: "三田市", value: "28219" },
        { value: "28220", text: "加西市" },
        { value: "28221", text: "丹波篠山市" },
        { text: "養父市", value: "28222" },
        { text: "丹波市", value: "28223" },
        { value: "28224", text: "南あわじ市" },
        { value: "28225", text: "朝来市" },
        { text: "淡路市", value: "28226" },
        { value: "28227", text: "宍粟市" },
        { value: "28228", text: "加東市" },
        { text: "たつの市", value: "28229" },
        { text: "川辺郡猪名川町", value: "28301" },
        { text: "多可郡多可町", value: "28365" },
        { text: "加古郡稲美町", value: "28381" },
        { value: "28382", text: "加古郡播磨町" },
        { text: "神崎郡市川町", value: "28442" },
        { text: "神崎郡福崎町", value: "28443" },
        { value: "28446", text: "神崎郡神河町" },
        { value: "28464", text: "揖保郡太子町" },
        { value: "28481", text: "赤穂郡上郡町" },
        { value: "28501", text: "佐用郡佐用町" },
        { value: "28585", text: "美方郡香美町" },
        { text: "美方郡新温泉町", value: "28586" },
      ],
      value: "28",
      text: "兵庫県",
    },
    {
      city: [
        { text: "奈良市", value: "29201" },
        { value: "29202", text: "大和高田市" },
        { value: "29203", text: "大和郡山市" },
        { value: "29204", text: "天理市" },
        { text: "橿原市", value: "29205" },
        { value: "29206", text: "桜井市" },
        { value: "29207", text: "五條市" },
        { value: "29208", text: "御所市" },
        { value: "29209", text: "生駒市" },
        { text: "香芝市", value: "29210" },
        { text: "葛城市", value: "29211" },
        { text: "宇陀市", value: "29212" },
        { value: "29322", text: "山辺郡山添村" },
        { value: "29342", text: "生駒郡平群町" },
        { value: "29343", text: "生駒郡三郷町" },
        { value: "29344", text: "生駒郡斑鳩町" },
        { value: "29345", text: "生駒郡安堵町" },
        { text: "磯城郡川西町", value: "29361" },
        { text: "磯城郡三宅町", value: "29362" },
        { value: "29363", text: "磯城郡田原本町" },
        { text: "宇陀郡曽爾村", value: "29385" },
        { value: "29386", text: "宇陀郡御杖村" },
        { value: "29401", text: "高市郡高取町" },
        { text: "高市郡明日香村", value: "29402" },
        { value: "29424", text: "北葛城郡上牧町" },
        { value: "29425", text: "北葛城郡王寺町" },
        { value: "29426", text: "北葛城郡広陵町" },
        { text: "北葛城郡河合町", value: "29427" },
        { value: "29441", text: "吉野郡吉野町" },
        { value: "29442", text: "吉野郡大淀町" },
        { value: "29443", text: "吉野郡下市町" },
        { text: "吉野郡黒滝村", value: "29444" },
        { text: "吉野郡天川村", value: "29446" },
        { text: "吉野郡野迫川村", value: "29447" },
        { text: "吉野郡十津川村", value: "29449" },
        { text: "吉野郡下北山村", value: "29450" },
        { value: "29451", text: "吉野郡上北山村" },
        { value: "29452", text: "吉野郡川上村" },
        { value: "29453", text: "吉野郡東吉野村" },
      ],
      value: "29",
      text: "奈良県",
    },
    {
      city: [
        { value: "30201", text: "和歌山市" },
        { value: "30202", text: "海南市" },
        { text: "橋本市", value: "30203" },
        { value: "30204", text: "有田市" },
        { value: "30205", text: "御坊市" },
        { text: "田辺市", value: "30206" },
        { text: "新宮市", value: "30207" },
        { text: "紀の川市", value: "30208" },
        { text: "岩出市", value: "30209" },
        { text: "海草郡紀美野町", value: "30304" },
        { text: "伊都郡かつらぎ町", value: "30341" },
        { value: "30343", text: "伊都郡九度山町" },
        { value: "30344", text: "伊都郡高野町" },
        { value: "30361", text: "有田郡湯浅町" },
        { text: "有田郡広川町", value: "30362" },
        { value: "30366", text: "有田郡有田川町" },
        { value: "30381", text: "日高郡美浜町" },
        { value: "30382", text: "日高郡日高町" },
        { value: "30383", text: "日高郡由良町" },
        { text: "日高郡印南町", value: "30390" },
        { value: "30391", text: "日高郡みなべ町" },
        { text: "日高郡日高川町", value: "30392" },
        { text: "西牟婁郡白浜町", value: "30401" },
        { value: "30404", text: "西牟婁郡上富田町" },
        { value: "30406", text: "西牟婁郡すさみ町" },
        { value: "30421", text: "東牟婁郡那智勝浦町" },
        { text: "東牟婁郡太地町", value: "30422" },
        { text: "東牟婁郡古座川町", value: "30424" },
        { value: "30427", text: "東牟婁郡北山村" },
        { value: "30428", text: "東牟婁郡串本町" },
      ],
      text: "和歌山県",
      value: "30",
    },
    {
      city: [
        { text: "鳥取市", value: "31201" },
        { text: "米子市", value: "31202" },
        { value: "31203", text: "倉吉市" },
        { text: "境港市", value: "31204" },
        { value: "31302", text: "岩美郡岩美町" },
        { value: "31325", text: "八頭郡若桜町" },
        { text: "八頭郡智頭町", value: "31328" },
        { value: "31329", text: "八頭郡八頭町" },
        { value: "31364", text: "東伯郡三朝町" },
        { value: "31370", text: "東伯郡湯梨浜町" },
        { value: "31371", text: "東伯郡琴浦町" },
        { value: "31372", text: "東伯郡北栄町" },
        { text: "西伯郡日吉津村", value: "31384" },
        { text: "西伯郡大山町", value: "31386" },
        { text: "西伯郡南部町", value: "31389" },
        { text: "西伯郡伯耆町", value: "31390" },
        { value: "31401", text: "日野郡日南町" },
        { value: "31402", text: "日野郡日野町" },
        { text: "日野郡江府町", value: "31403" },
      ],
      value: "31",
      text: "鳥取県",
    },
    {
      value: "32",
      text: "島根県",
      city: [
        { text: "松江市", value: "32201" },
        { text: "浜田市", value: "32202" },
        { text: "出雲市", value: "32203" },
        { text: "益田市", value: "32204" },
        { text: "大田市", value: "32205" },
        { value: "32206", text: "安来市" },
        { value: "32207", text: "江津市" },
        { value: "32209", text: "雲南市" },
        { value: "32343", text: "仁多郡奥出雲町" },
        { text: "飯石郡飯南町", value: "32386" },
        { value: "32441", text: "邑智郡川本町" },
        { text: "邑智郡美郷町", value: "32448" },
        { text: "邑智郡邑南町", value: "32449" },
        { value: "32501", text: "鹿足郡津和野町" },
        { value: "32505", text: "鹿足郡吉賀町" },
        { value: "32525", text: "隠岐郡海士町" },
        { text: "隠岐郡西ノ島町", value: "32526" },
        { value: "32527", text: "隠岐郡知夫村" },
        { value: "32528", text: "隠岐郡隠岐の島町" },
      ],
    },
    {
      city: [
        { text: "岡山市", value: "33100" },
        { value: "33101", text: "岡山市北区" },
        { text: "岡山市中区", value: "33102" },
        { value: "33103", text: "岡山市東区" },
        { value: "33104", text: "岡山市南区" },
        { text: "倉敷市", value: "33202" },
        { text: "津山市", value: "33203" },
        { text: "玉野市", value: "33204" },
        { value: "33205", text: "笠岡市" },
        { value: "33207", text: "井原市" },
        { value: "33208", text: "総社市" },
        { value: "33209", text: "高梁市" },
        { text: "新見市", value: "33210" },
        { value: "33211", text: "備前市" },
        { text: "瀬戸内市", value: "33212" },
        { text: "赤磐市", value: "33213" },
        { value: "33214", text: "真庭市" },
        { value: "33215", text: "美作市" },
        { value: "33216", text: "浅口市" },
        { value: "33346", text: "和気郡和気町" },
        { text: "都窪郡早島町", value: "33423" },
        { value: "33445", text: "浅口郡里庄町" },
        { value: "33461", text: "小田郡矢掛町" },
        { value: "33586", text: "真庭郡新庄村" },
        { value: "33606", text: "苫田郡鏡野町" },
        { text: "勝田郡勝央町", value: "33622" },
        { value: "33623", text: "勝田郡奈義町" },
        { value: "33643", text: "英田郡西粟倉村" },
        { value: "33663", text: "久米郡久米南町" },
        { value: "33666", text: "久米郡美咲町" },
        { value: "33681", text: "加賀郡吉備中央町" },
      ],
      value: "33",
      text: "岡山県",
    },
    {
      value: "34",
      text: "広島県",
      city: [
        { value: "34100", text: "広島市" },
        { value: "34101", text: "広島市中区" },
        { text: "広島市東区", value: "34102" },
        { text: "広島市南区", value: "34103" },
        { text: "広島市西区", value: "34104" },
        { value: "34105", text: "広島市安佐南区" },
        { value: "34106", text: "広島市安佐北区" },
        { value: "34107", text: "広島市安芸区" },
        { text: "広島市佐伯区", value: "34108" },
        { value: "34202", text: "呉市" },
        { text: "竹原市", value: "34203" },
        { value: "34204", text: "三原市" },
        { text: "尾道市", value: "34205" },
        { value: "34207", text: "福山市" },
        { value: "34208", text: "府中市" },
        { value: "34209", text: "三次市" },
        { value: "34210", text: "庄原市" },
        { value: "34211", text: "大竹市" },
        { text: "東広島市", value: "34212" },
        { value: "34213", text: "廿日市市" },
        { text: "安芸高田市", value: "34214" },
        { value: "34215", text: "江田島市" },
        { text: "安芸郡府中町", value: "34302" },
        { value: "34304", text: "安芸郡海田町" },
        { text: "安芸郡熊野町", value: "34307" },
        { text: "安芸郡坂町", value: "34309" },
        { value: "34368", text: "山県郡安芸太田町" },
        { text: "山県郡北広島町", value: "34369" },
        { value: "34431", text: "豊田郡大崎上島町" },
        { value: "34462", text: "世羅郡世羅町" },
        { text: "神石郡神石高原町", value: "34545" },
      ],
    },
    {
      city: [
        { text: "下関市", value: "35201" },
        { value: "35202", text: "宇部市" },
        { text: "山口市", value: "35203" },
        { value: "35204", text: "萩市" },
        { value: "35206", text: "防府市" },
        { value: "35207", text: "下松市" },
        { value: "35208", text: "岩国市" },
        { text: "光市", value: "35210" },
        { text: "長門市", value: "35211" },
        { text: "柳井市", value: "35212" },
        { value: "35213", text: "美祢市" },
        { text: "周南市", value: "35215" },
        { text: "山陽小野田市", value: "35216" },
        { value: "35305", text: "大島郡周防大島町" },
        { text: "玖珂郡和木町", value: "35321" },
        { text: "熊毛郡上関町", value: "35341" },
        { text: "熊毛郡田布施町", value: "35343" },
        { value: "35344", text: "熊毛郡平生町" },
        { value: "35502", text: "阿武郡阿武町" },
      ],
      value: "35",
      text: "山口県",
    },
    {
      city: [
        { value: "36201", text: "徳島市" },
        { text: "鳴門市", value: "36202" },
        { text: "小松島市", value: "36203" },
        { value: "36204", text: "阿南市" },
        { text: "吉野川市", value: "36205" },
        { text: "阿波市", value: "36206" },
        { value: "36207", text: "美馬市" },
        { text: "三好市", value: "36208" },
        { value: "36301", text: "勝浦郡勝浦町" },
        { text: "勝浦郡上勝町", value: "36302" },
        { text: "名東郡佐那河内村", value: "36321" },
        { value: "36341", text: "名西郡石井町" },
        { value: "36342", text: "名西郡神山町" },
        { value: "36368", text: "那賀郡那賀町" },
        { value: "36383", text: "海部郡牟岐町" },
        { text: "海部郡美波町", value: "36387" },
        { text: "海部郡海陽町", value: "36388" },
        { value: "36401", text: "板野郡松茂町" },
        { text: "板野郡北島町", value: "36402" },
        { text: "板野郡藍住町", value: "36403" },
        { value: "36404", text: "板野郡板野町" },
        { text: "板野郡上板町", value: "36405" },
        { value: "36468", text: "美馬郡つるぎ町" },
        { value: "36489", text: "三好郡東みよし町" },
      ],
      value: "36",
      text: "徳島県",
    },
    {
      text: "香川県",
      value: "37",
      city: [
        { value: "37201", text: "高松市" },
        { text: "丸亀市", value: "37202" },
        { value: "37203", text: "坂出市" },
        { text: "善通寺市", value: "37204" },
        { text: "観音寺市", value: "37205" },
        { text: "さぬき市", value: "37206" },
        { text: "東かがわ市", value: "37207" },
        { value: "37208", text: "三豊市" },
        { value: "37322", text: "小豆郡土庄町" },
        { value: "37324", text: "小豆郡小豆島町" },
        { value: "37341", text: "木田郡三木町" },
        { text: "香川郡直島町", value: "37364" },
        { text: "綾歌郡宇多津町", value: "37386" },
        { text: "綾歌郡綾川町", value: "37387" },
        { text: "仲多度郡琴平町", value: "37403" },
        { text: "仲多度郡多度津町", value: "37404" },
        { text: "仲多度郡まんのう町", value: "37406" },
      ],
    },
    {
      value: "38",
      text: "愛媛県",
      city: [
        { text: "松山市", value: "38201" },
        { text: "今治市", value: "38202" },
        { text: "宇和島市", value: "38203" },
        { text: "八幡浜市", value: "38204" },
        { text: "新居浜市", value: "38205" },
        { text: "西条市", value: "38206" },
        { value: "38207", text: "大洲市" },
        { value: "38210", text: "伊予市" },
        { value: "38213", text: "四国中央市" },
        { text: "西予市", value: "38214" },
        { text: "東温市", value: "38215" },
        { value: "38356", text: "越智郡上島町" },
        { text: "上浮穴郡久万高原町", value: "38386" },
        { value: "38401", text: "伊予郡松前町" },
        { text: "伊予郡砥部町", value: "38402" },
        { text: "喜多郡内子町", value: "38422" },
        { value: "38442", text: "西宇和郡伊方町" },
        { text: "北宇和郡松野町", value: "38484" },
        { value: "38488", text: "北宇和郡鬼北町" },
        { value: "38506", text: "南宇和郡愛南町" },
      ],
    },
    {
      city: [
        { value: "39201", text: "高知市" },
        { value: "39202", text: "室戸市" },
        { text: "安芸市", value: "39203" },
        { value: "39204", text: "南国市" },
        { value: "39205", text: "土佐市" },
        { value: "39206", text: "須崎市" },
        { text: "宿毛市", value: "39208" },
        { value: "39209", text: "土佐清水市" },
        { value: "39210", text: "四万十市" },
        { text: "香南市", value: "39211" },
        { value: "39212", text: "香美市" },
        { value: "39301", text: "安芸郡東洋町" },
        { value: "39302", text: "安芸郡奈半利町" },
        { text: "安芸郡田野町", value: "39303" },
        { value: "39304", text: "安芸郡安田町" },
        { value: "39305", text: "安芸郡北川村" },
        { value: "39306", text: "安芸郡馬路村" },
        { value: "39307", text: "安芸郡芸西村" },
        { value: "39341", text: "長岡郡本山町" },
        { value: "39344", text: "長岡郡大豊町" },
        { value: "39363", text: "土佐郡土佐町" },
        { text: "土佐郡大川村", value: "39364" },
        { value: "39386", text: "吾川郡いの町" },
        { text: "吾川郡仁淀川町", value: "39387" },
        { value: "39401", text: "高岡郡中土佐町" },
        { text: "高岡郡佐川町", value: "39402" },
        { text: "高岡郡越知町", value: "39403" },
        { text: "高岡郡檮原町", value: "39405" },
        { text: "高岡郡日高村", value: "39410" },
        { value: "39411", text: "高岡郡津野町" },
        { text: "高岡郡四万十町", value: "39412" },
        { text: "幡多郡大月町", value: "39424" },
        { text: "幡多郡三原村", value: "39427" },
        { value: "39428", text: "幡多郡黒潮町" },
      ],
      text: "高知県",
      value: "39",
    },
    {
      text: "福岡県",
      value: "40",
      city: [
        { text: "北九州市", value: "40100" },
        { value: "40101", text: "北九州市門司区" },
        { text: "北九州市若松区", value: "40103" },
        { text: "北九州市戸畑区", value: "40105" },
        { value: "40106", text: "北九州市小倉北区" },
        { text: "北九州市小倉南区", value: "40107" },
        { text: "北九州市八幡東区", value: "40108" },
        { text: "北九州市八幡西区", value: "40109" },
        { text: "福岡市", value: "40130" },
        { value: "40131", text: "福岡市東区" },
        { value: "40132", text: "福岡市博多区" },
        { text: "福岡市中央区", value: "40133" },
        { value: "40134", text: "福岡市南区" },
        { text: "福岡市西区", value: "40135" },
        { value: "40136", text: "福岡市城南区" },
        { value: "40137", text: "福岡市早良区" },
        { value: "40202", text: "大牟田市" },
        { value: "40203", text: "久留米市" },
        { text: "直方市", value: "40204" },
        { text: "飯塚市", value: "40205" },
        { text: "田川市", value: "40206" },
        { value: "40207", text: "柳川市" },
        { text: "八女市", value: "40210" },
        { value: "40211", text: "筑後市" },
        { value: "40212", text: "大川市" },
        { text: "行橋市", value: "40213" },
        { value: "40214", text: "豊前市" },
        { value: "40215", text: "中間市" },
        { text: "小郡市", value: "40216" },
        { text: "筑紫野市", value: "40217" },
        { value: "40218", text: "春日市" },
        { text: "大野城市", value: "40219" },
        { value: "40220", text: "宗像市" },
        { value: "40221", text: "太宰府市" },
        { text: "古賀市", value: "40223" },
        { value: "40224", text: "福津市" },
        { text: "うきは市", value: "40225" },
        { text: "宮若市", value: "40226" },
        { value: "40227", text: "嘉麻市" },
        { value: "40228", text: "朝倉市" },
        { value: "40229", text: "みやま市" },
        { text: "糸島市", value: "40230" },
        { text: "那珂川市", value: "40231" },
        { value: "40341", text: "糟屋郡宇美町" },
        { text: "糟屋郡篠栗町", value: "40342" },
        { value: "40343", text: "糟屋郡志免町" },
        { text: "糟屋郡須恵町", value: "40344" },
        { text: "糟屋郡新宮町", value: "40345" },
        { text: "糟屋郡久山町", value: "40348" },
        { value: "40349", text: "糟屋郡粕屋町" },
        { value: "40381", text: "遠賀郡芦屋町" },
        { value: "40382", text: "遠賀郡水巻町" },
        { text: "遠賀郡岡垣町", value: "40383" },
        { value: "40384", text: "遠賀郡遠賀町" },
        { value: "40401", text: "鞍手郡小竹町" },
        { value: "40402", text: "鞍手郡鞍手町" },
        { text: "嘉穂郡桂川町", value: "40421" },
        { value: "40447", text: "朝倉郡筑前町" },
        { value: "40448", text: "朝倉郡東峰村" },
        { text: "三井郡大刀洗町", value: "40503" },
        { value: "40522", text: "三潴郡大木町" },
        { value: "40544", text: "八女郡広川町" },
        { value: "40601", text: "田川郡香春町" },
        { text: "田川郡添田町", value: "40602" },
        { text: "田川郡糸田町", value: "40604" },
        { text: "田川郡川崎町", value: "40605" },
        { value: "40608", text: "田川郡大任町" },
        { value: "40609", text: "田川郡赤村" },
        { value: "40610", text: "田川郡福智町" },
        { text: "京都郡苅田町", value: "40621" },
        { value: "40625", text: "京都郡みやこ町" },
        { text: "築上郡吉富町", value: "40642" },
        { text: "築上郡上毛町", value: "40646" },
        { value: "40647", text: "築上郡築上町" },
      ],
    },
    {
      text: "佐賀県",
      value: "41",
      city: [
        { text: "佐賀市", value: "41201" },
        { text: "唐津市", value: "41202" },
        { value: "41203", text: "鳥栖市" },
        { value: "41204", text: "多久市" },
        { text: "伊万里市", value: "41205" },
        { text: "武雄市", value: "41206" },
        { text: "鹿島市", value: "41207" },
        { value: "41208", text: "小城市" },
        { text: "嬉野市", value: "41209" },
        { value: "41210", text: "神埼市" },
        { value: "41327", text: "神埼郡吉野ヶ里町" },
        { text: "三養基郡基山町", value: "41341" },
        { text: "三養基郡上峰町", value: "41345" },
        { value: "41346", text: "三養基郡みやき町" },
        { text: "東松浦郡玄海町", value: "41387" },
        { value: "41401", text: "西松浦郡有田町" },
        { value: "41423", text: "杵島郡大町町" },
        { value: "41424", text: "杵島郡江北町" },
        { text: "杵島郡白石町", value: "41425" },
        { value: "41441", text: "藤津郡太良町" },
      ],
    },
    {
      city: [
        { text: "長崎市", value: "42201" },
        { value: "42202", text: "佐世保市" },
        { text: "島原市", value: "42203" },
        { value: "42204", text: "諫早市" },
        { text: "大村市", value: "42205" },
        { text: "平戸市", value: "42207" },
        { value: "42208", text: "松浦市" },
        { text: "対馬市", value: "42209" },
        { value: "42210", text: "壱岐市" },
        { value: "42211", text: "五島市" },
        { text: "西海市", value: "42212" },
        { value: "42213", text: "雲仙市" },
        { value: "42214", text: "南島原市" },
        { text: "西彼杵郡長与町", value: "42307" },
        { text: "西彼杵郡時津町", value: "42308" },
        { value: "42321", text: "東彼杵郡東彼杵町" },
        { text: "東彼杵郡川棚町", value: "42322" },
        { text: "東彼杵郡波佐見町", value: "42323" },
        { value: "42383", text: "北松浦郡小値賀町" },
        { value: "42391", text: "北松浦郡佐々町" },
        { value: "42411", text: "南松浦郡新上五島町" },
      ],
      text: "長崎県",
      value: "42",
    },
    {
      value: "43",
      text: "熊本県",
      city: [
        { value: "43100", text: "熊本市" },
        { value: "43101", text: "熊本市中央区" },
        { value: "43102", text: "熊本市東区" },
        { text: "熊本市西区", value: "43103" },
        { text: "熊本市南区", value: "43104" },
        { text: "熊本市北区", value: "43105" },
        { text: "八代市", value: "43202" },
        { text: "人吉市", value: "43203" },
        { value: "43204", text: "荒尾市" },
        { text: "水俣市", value: "43205" },
        { value: "43206", text: "玉名市" },
        { value: "43208", text: "山鹿市" },
        { text: "菊池市", value: "43210" },
        { value: "43211", text: "宇土市" },
        { text: "上天草市", value: "43212" },
        { value: "43213", text: "宇城市" },
        { text: "阿蘇市", value: "43214" },
        { value: "43215", text: "天草市" },
        { text: "合志市", value: "43216" },
        { text: "下益城郡美里町", value: "43348" },
        { text: "玉名郡玉東町", value: "43364" },
        { value: "43367", text: "玉名郡南関町" },
        { text: "玉名郡長洲町", value: "43368" },
        { value: "43369", text: "玉名郡和水町" },
        { value: "43403", text: "菊池郡大津町" },
        { text: "菊池郡菊陽町", value: "43404" },
        { value: "43423", text: "阿蘇郡南小国町" },
        { text: "阿蘇郡小国町", value: "43424" },
        { value: "43425", text: "阿蘇郡産山村" },
        { value: "43428", text: "阿蘇郡高森町" },
        { value: "43432", text: "阿蘇郡西原村" },
        { value: "43433", text: "阿蘇郡南阿蘇村" },
        { text: "上益城郡御船町", value: "43441" },
        { text: "上益城郡嘉島町", value: "43442" },
        { text: "上益城郡益城町", value: "43443" },
        { value: "43444", text: "上益城郡甲佐町" },
        { text: "上益城郡山都町", value: "43447" },
        { text: "八代郡氷川町", value: "43468" },
        { text: "葦北郡芦北町", value: "43482" },
        { value: "43484", text: "葦北郡津奈木町" },
        { text: "球磨郡錦町", value: "43501" },
        { value: "43505", text: "球磨郡多良木町" },
        { text: "球磨郡湯前町", value: "43506" },
        { value: "43507", text: "球磨郡水上村" },
        { value: "43510", text: "球磨郡相良村" },
        { value: "43511", text: "球磨郡五木村" },
        { text: "球磨郡山江村", value: "43512" },
        { value: "43513", text: "球磨郡球磨村" },
        { value: "43514", text: "球磨郡あさぎり町" },
        { value: "43531", text: "天草郡苓北町" },
      ],
    },
    {
      city: [
        { value: "44201", text: "大分市" },
        { value: "44202", text: "別府市" },
        { value: "44203", text: "中津市" },
        { value: "44204", text: "日田市" },
        { value: "44205", text: "佐伯市" },
        { text: "臼杵市", value: "44206" },
        { value: "44207", text: "津久見市" },
        { value: "44208", text: "竹田市" },
        { text: "豊後高田市", value: "44209" },
        { value: "44210", text: "杵築市" },
        { value: "44211", text: "宇佐市" },
        { value: "44212", text: "豊後大野市" },
        { value: "44213", text: "由布市" },
        { text: "国東市", value: "44214" },
        { text: "東国東郡姫島村", value: "44322" },
        { text: "速見郡日出町", value: "44341" },
        { value: "44461", text: "玖珠郡九重町" },
        { value: "44462", text: "玖珠郡玖珠町" },
      ],
      value: "44",
      text: "大分県",
    },
    {
      city: [
        { text: "宮崎市", value: "45201" },
        { text: "都城市", value: "45202" },
        { value: "45203", text: "延岡市" },
        { value: "45204", text: "日南市" },
        { text: "小林市", value: "45205" },
        { value: "45206", text: "日向市" },
        { value: "45207", text: "串間市" },
        { text: "西都市", value: "45208" },
        { value: "45209", text: "えびの市" },
        { value: "45341", text: "北諸県郡三股町" },
        { value: "45361", text: "西諸県郡高原町" },
        { text: "東諸県郡国富町", value: "45382" },
        { text: "東諸県郡綾町", value: "45383" },
        { text: "児湯郡高鍋町", value: "45401" },
        { value: "45402", text: "児湯郡新富町" },
        { text: "児湯郡西米良村", value: "45403" },
        { text: "児湯郡木城町", value: "45404" },
        { value: "45405", text: "児湯郡川南町" },
        { text: "児湯郡都農町", value: "45406" },
        { text: "東臼杵郡門川町", value: "45421" },
        { value: "45429", text: "東臼杵郡諸塚村" },
        { text: "東臼杵郡椎葉村", value: "45430" },
        { text: "東臼杵郡美郷町", value: "45431" },
        { value: "45441", text: "西臼杵郡高千穂町" },
        { value: "45442", text: "西臼杵郡日之影町" },
        { value: "45443", text: "西臼杵郡五ヶ瀬町" },
      ],
      value: "45",
      text: "宮崎県",
    },
    {
      value: "46",
      text: "鹿児島県",
      city: [
        { text: "鹿児島市", value: "46201" },
        { value: "46203", text: "鹿屋市" },
        { value: "46204", text: "枕崎市" },
        { text: "阿久根市", value: "46206" },
        { text: "出水市", value: "46208" },
        { text: "指宿市", value: "46210" },
        { text: "西之表市", value: "46213" },
        { value: "46214", text: "垂水市" },
        { value: "46215", text: "薩摩川内市" },
        { text: "日置市", value: "46216" },
        { value: "46217", text: "曽於市" },
        { text: "霧島市", value: "46218" },
        { text: "いちき串木野市", value: "46219" },
        { text: "南さつま市", value: "46220" },
        { value: "46221", text: "志布志市" },
        { value: "46222", text: "奄美市" },
        { text: "南九州市", value: "46223" },
        { text: "伊佐市", value: "46224" },
        { text: "姶良市", value: "46225" },
        { value: "46303", text: "鹿児島郡三島村" },
        { text: "鹿児島郡十島村", value: "46304" },
        { value: "46392", text: "薩摩郡さつま町" },
        { value: "46404", text: "出水郡長島町" },
        { text: "姶良郡湧水町", value: "46452" },
        { text: "曽於郡大崎町", value: "46468" },
        { text: "肝属郡東串良町", value: "46482" },
        { value: "46490", text: "肝属郡錦江町" },
        { text: "肝属郡南大隅町", value: "46491" },
        { text: "肝属郡肝付町", value: "46492" },
        { value: "46501", text: "熊毛郡中種子町" },
        { value: "46502", text: "熊毛郡南種子町" },
        { text: "熊毛郡屋久島町", value: "46505" },
        { value: "46523", text: "大島郡大和村" },
        { text: "大島郡宇検村", value: "46524" },
        { value: "46525", text: "大島郡瀬戸内町" },
        { value: "46527", text: "大島郡龍郷町" },
        { value: "46529", text: "大島郡喜界町" },
        { text: "大島郡徳之島町", value: "46530" },
        { text: "大島郡天城町", value: "46531" },
        { text: "大島郡伊仙町", value: "46532" },
        { text: "大島郡和泊町", value: "46533" },
        { value: "46534", text: "大島郡知名町" },
        { value: "46535", text: "大島郡与論町" },
      ],
    },
    {
      city: [
        { value: "47201", text: "那覇市" },
        { text: "宜野湾市", value: "47205" },
        { text: "石垣市", value: "47207" },
        { text: "浦添市", value: "47208" },
        { text: "名護市", value: "47209" },
        { value: "47210", text: "糸満市" },
        { text: "沖縄市", value: "47211" },
        { text: "豊見城市", value: "47212" },
        { text: "うるま市", value: "47213" },
        { text: "宮古島市", value: "47214" },
        { value: "47215", text: "南城市" },
        { value: "47301", text: "国頭郡国頭村" },
        { text: "国頭郡大宜味村", value: "47302" },
        { value: "47303", text: "国頭郡東村" },
        { text: "国頭郡今帰仁村", value: "47306" },
        { value: "47308", text: "国頭郡本部町" },
        { value: "47311", text: "国頭郡恩納村" },
        { value: "47313", text: "国頭郡宜野座村" },
        { value: "47314", text: "国頭郡金武町" },
        { text: "国頭郡伊江村", value: "47315" },
        { text: "中頭郡読谷村", value: "47324" },
        { text: "中頭郡嘉手納町", value: "47325" },
        { value: "47326", text: "中頭郡北谷町" },
        { text: "中頭郡北中城村", value: "47327" },
        { text: "中頭郡中城村", value: "47328" },
        { value: "47329", text: "中頭郡西原町" },
        { value: "47348", text: "島尻郡与那原町" },
        { text: "島尻郡南風原町", value: "47350" },
        { text: "島尻郡渡嘉敷村", value: "47353" },
        { value: "47354", text: "島尻郡座間味村" },
        { value: "47355", text: "島尻郡粟国村" },
        { text: "島尻郡渡名喜村", value: "47356" },
        { value: "47357", text: "島尻郡南大東村" },
        { value: "47358", text: "島尻郡北大東村" },
        { text: "島尻郡伊平屋村", value: "47359" },
        { value: "47360", text: "島尻郡伊是名村" },
        { text: "島尻郡久米島町", value: "47361" },
        { value: "47362", text: "島尻郡八重瀬町" },
        { value: "47375", text: "宮古郡多良間村" },
        { text: "八重山郡竹富町", value: "47381" },
        { text: "八重山郡与那国町", value: "47382" },
      ],
      text: "沖縄県",
      value: "47",
    },
  ],

  CITY_CODE_MAP: {
    "40349": "糟屋郡粕屋町",
    "43514": "球磨郡あさぎり町",
    "14100": "横浜市",
    "35204": "萩市",
    "01631": "十勝総合振興局音更町",
    "43215": "天草市",
    "39341": "長岡郡本山町",
    "42307": "西彼杵郡長与町",
    "01202": "函館市",
    "07547": "双葉郡浪江町",
    "03485": "下閉伊郡普代村",
    "06209": "長井市",
    "43404": "菊池郡菊陽町",
    "06381": "東置賜郡高畠町",
    "43105": "熊本市北区",
    "14366": "足柄上郡開成町",
    "14210": "三浦市",
    "07545": "双葉郡大熊町",
    "02384": "北津軽郡鶴田町",
    "40214": "豊前市",
    "07408": "耶麻郡猪苗代町",
    "29210": "香芝市",
    "27217": "松原市",
    "28110": "神戸市中央区",
    "26365": "相楽郡和束町",
    "10201": "前橋市",
    "20363": "諏訪郡原村",
    "18423": "丹生郡越前町",
    "18210": "坂井市",
    "05209": "鹿角市",
    "44208": "竹田市",
    "22130": "浜松市",
    "08201": "水戸市",
    "02406": "上北郡横浜町",
    "27215": "寝屋川市",
    "33203": "津山市",
    "03321": "紫波郡紫波町",
    "37202": "丸亀市",
    "26367": "相楽郡南山城村",
    "12230": "八街市",
    "17207": "羽咋市",
    "06322": "西村山郡西川町",
    "12220": "流山市",
    "20211": "中野市",
    "32201": "松江市",
    "02323": "西津軽郡深浦町",
    "27107": "大阪市港区",
    "45403": "児湯郡西米良村",
    "01697": "根室振興局留夜別村",
    "20303": "南佐久郡小海町",
    "41206": "武雄市",
    "01695": "根室振興局色丹村",
    "36204": "阿南市",
    "09211": "矢板市",
    "17205": "珠洲市",
    "01586": "胆振総合振興局むかわ町",
    "32526": "隠岐郡西ノ島町",
    "24441": "多気郡多気町",
    "40229": "みやま市",
    "40224": "福津市",
    "46523": "大島郡大和村",
    "21215": "山県市",
    "31202": "米子市",
    "04323": "柴田郡柴田町",
    "21217": "飛騨市",
    "15108": "新潟市西蒲区",
    "01408": "後志総合振興局余市町",
    "25208": "栗東市",
    "01468": "上川総合振興局下川町",
    "01334": "渡島総合振興局木古内町",
    "01518": "宗谷総合振興局利尻町",
    "45441": "西臼杵郡高千穂町",
    "04406": "宮城郡利府町",
    "11207": "秩父市",
    "46533": "大島郡和泊町",
    "42208": "松浦市",
    "05204": "大館市",
    "21404": "揖斐郡池田町",
    "15218": "五泉市",
    "10384": "甘楽郡甘楽町",
    "39303": "安芸郡田野町",
    "31329": "八頭郡八頭町",
    "02201": "青森市",
    "40219": "大野城市",
    "23116": "名古屋市天白区",
    "39211": "香南市",
    "44462": "玖珠郡玖珠町",
    "06204": "酒田市",
    "12423": "長生郡長生村",
    "12210": "茂原市",
    "20602": "下水内郡栄村",
    "27225": "高石市",
    "41401": "西松浦郡有田町",
    "23206": "春日井市",
    "43442": "上益城郡嘉島町",
    "40109": "北九州市八幡西区",
    "13106": "台東区",
    "37324": "小豆郡小豆島町",
    "01545": "オホーツク総合振興局斜里町",
    "13421": "小笠原支庁小笠原村",
    "12100": "千葉市",
    "01547": "オホーツク総合振興局小清水町",
    "07202": "会津若松市",
    "39363": "土佐郡土佐町",
    "03203": "大船渡市",
    "40344": "糟屋郡須恵町",
    "20416": "下伊那郡豊丘村",
    "27227": "東大阪市",
    "30366": "有田郡有田川町",
    "26208": "向日市",
    "38201": "松山市",
    "20207": "須坂市",
    "09205": "鹿沼市",
    "17211": "能美市",
    "06206": "寒河江市",
    "37403": "仲多度郡琴平町",
    "27111": "大阪市浪速区",
    "30304": "海草郡紀美野町",
    "03208": "遠野市",
    "23114": "名古屋市緑区",
    "30428": "東牟婁郡串本町",
    "13214": "国分寺市",
    "20205": "飯田市",
    "26203": "綾部市",
    "14342": "中郡二宮町",
    "12102": "千葉市花見川区",
    "11341": "比企郡滑川町",
    "39424": "幡多郡大月町",
    "13362": "大島支庁利島村",
    "41425": "杵島郡白石町",
    "20414": "下伊那郡泰阜村",
    "08215": "北茨城市",
    "12212": "佐倉市",
    "08217": "取手市",
    "13104": "新宿区",
    "36401": "板野郡松茂町",
    "23204": "瀬戸市",
    "45202": "都城市",
    "10464": "佐波郡玉村町",
    "01463": "上川総合振興局占冠村",
    "29447": "吉野郡野迫川村",
    "24324": "員弁郡東員町",
    "01513": "宗谷総合振興局中頓別町",
    "32386": "飯石郡飯南町",
    "02442": "三戸郡五戸町",
    "42203": "島原市",
    "41209": "嬉野市",
    "11231": "桶川市",
    "13229": "西東京市",
    "40226": "宮若市",
    "15213": "燕市",
    "04215": "大崎市",
    "29342": "生駒郡平群町",
    "01371": "檜山振興局せたな町",
    "05206": "男鹿市",
    "20429": "木曽郡王滝村",
    "11221": "草加市",
    "04404": "宮城郡七ヶ浜町",
    "04105": "仙台市泉区",
    "15103": "新潟市中央区",
    "38442": "西宇和郡伊方町",
    "01403": "後志総合振興局泊村",
    "40608": "田川郡大任町",
    "25203": "長浜市",
    "15361": "南蒲原郡田上町",
    "38215": "東温市",
    "47209": "名護市",
    "28202": "尼崎市",
    "47381": "八重山郡竹富町",
    "12222": "我孫子市",
    "19202": "富士吉田市",
    "08227": "筑西市",
    "10524": "邑楽郡大泉町",
    "08225": "常陸大宮市",
    "06401": "西置賜郡小国町",
    "03402": "西磐井郡平泉町",
    "13224": "多摩市",
    "01584": "胆振総合振興局洞爺湖町",
    "36206": "阿波市",
    "39205": "土佐市",
    "23427": "海部郡飛島村",
    "41204": "多久市",
    "29212": "宇陀市",
    "06461": "飽海郡遊佐町",
    "08235": "つくばみらい市",
    "27121": "大阪市東住吉区",
    "12232": "白井市",
    "23425": "海部郡蟹江町",
    "02450": "三戸郡新郷村",
    "44203": "中津市",
    "22132": "浜松市東区",
    "33208": "総社市",
    "23209": "碧南市",
    "34107": "広島市安芸区",
    "31371": "東伯郡琴浦町",
    "35206": "防府市",
    "40421": "嘉穂郡桂川町",
    "01667": "釧路総合振興局鶴居村",
    "40106": "北九州市小倉北区",
    "13109": "品川区",
    "01665": "釧路総合振興局弟子屈町",
    "11211": "本庄市",
    "14212": "厚木市",
    "45383": "東諸県郡綾町",
    "38386": "上浮穴郡久万高原町",
    "08442": "稲敷郡美浦村",
    "31403": "日野郡江府町",
    "46468": "曽於郡大崎町",
    "28586": "美方郡新温泉町",
    "34105": "広島市安佐南区",
    "15223": "阿賀野市",
    "01110": "札幌市清田区",
    "40216": "小郡市",
    "13219": "狛江市",
    "14364": "足柄上郡山北町",
    "34215": "江田島市",
    "16343": "下新川郡朝日町",
    "10345": "北群馬郡吉岡町",
    "21201": "岐阜市",
    "24202": "四日市市",
    "11363": "秩父郡長瀞町",
    "12342": "香取郡神崎町",
    "11101": "さいたま市西区",
    "14102": "横浜市神奈川区",
    "42321": "東彼杵郡東彼杵町",
    "01555": "オホーツク総合振興局遠軽町",
    "01607": "日高振興局浦河町",
    "21362": "不破郡関ケ原町",
    "14203": "平塚市",
    "27142": "堺市中区",
    "01337": "渡島総合振興局七飯町",
    "01218": "赤平市",
    "27219": "和泉市",
    "04421": "黒川郡大和町",
    "05207": "湯沢市",
    "01432": "空知総合振興局新十津川町",
    "30382": "日高郡日高町",
    "40227": "嘉麻市",
    "14113": "横浜市緑区",
    "17209": "かほく市",
    "04216": "富谷市",
    "21302": "羽島郡岐南町",
    "40225": "うきは市",
    "29446": "吉野郡天川村",
    "46223": "南九州市",
    "21214": "可児市",
    "27109": "大阪市天王寺区",
    "01108": "札幌市厚別区",
    "01699": "根室振興局紗那村",
    "13121": "足立区",
    "08216": "笠間市",
    "28301": "川辺郡猪名川町",
    "40345": "糟屋郡新宮町",
    "02411": "上北郡六ヶ所村",
    "03503": "九戸郡野田村",
    "07502": "石川郡玉川村",
    "33100": "岡山市",
    "23221": "新城市",
    "28213": "西脇市",
    "27224": "摂津市",
    "01481": "留萌振興局増毛町",
    "39401": "高岡郡中土佐町",
    "19213": "甲州市",
    "01544": "オホーツク総合振興局津別町",
    "33210": "新見市",
    "44212": "豊後大野市",
    "20521": "埴科郡坂城町",
    "33423": "都窪郡早島町",
    "19430": "南都留郡富士河口湖町",
    "06205": "新庄市",
    "09206": "日光市",
    "36368": "那賀郡那賀町",
    "01391": "後志総合振興局島牧村",
    "23231": "田原市",
    "20206": "諏訪市",
    "29203": "大和郡山市",
    "06207": "上山市",
    "22223": "御前崎市",
    "10421": "吾妻郡中之条町",
    "43104": "熊本市南区",
    "42212": "西海市",
    "07481": "東白川郡棚倉町",
    "07544": "双葉郡川内村",
    "31325": "八頭郡若桜町",
    "34431": "豊田郡大崎上島町",
    "15202": "長岡市",
    "46213": "西之表市",
    "40215": "中間市",
    "18382": "今立郡池田町",
    "40217": "筑紫野市",
    "02387": "北津軽郡中泊町",
    "03484": "下閉伊郡田野畑村",
    "04501": "遠田郡涌谷町",
    "01549": "オホーツク総合振興局訓子府町",
    "40105": "北九州市戸畑区",
    "40610": "田川郡福智町",
    "47303": "国頭郡東村",
    "27229": "四條畷市",
    "01228": "深川市",
    "01562": "オホーツク総合振興局西興部村",
    "41341": "三養基郡基山町",
    "25212": "高島市",
    "14321": "高座郡寒川町",
    "14133": "川崎市中原区",
    "43214": "阿蘇市",
    "28585": "美方郡香美町",
    "34106": "広島市安佐北区",
    "35207": "下松市",
    "47211": "沖縄市",
    "05434": "仙北郡美郷町",
    "40107": "北九州市小倉南区",
    "05368": "南秋田郡大潟村",
    "21219": "郡上市",
    "08236": "小美玉市",
    "01694": "根室振興局羅臼町",
    "22213": "掛川市",
    "03366": "和賀郡西和賀町",
    "39206": "須崎市",
    "27104": "大阪市此花区",
    "30203": "橋本市",
    "17204": "輪島市",
    "36205": "吉野川市",
    "07422": "河沼郡湯川村",
    "45361": "西諸県郡高原町",
    "26212": "京丹後市",
    "03210": "陸前高田市",
    "22301": "賀茂郡東伊豆町",
    "36207": "美馬市",
    "27362": "泉南郡田尻町",
    "32501": "鹿足郡津和野町",
    "45430": "東臼杵郡椎葉村",
    "13201": "八王子市",
    "15586": "岩船郡粟島浦村",
    "23101": "名古屋市千種区",
    "11242": "日高市",
    "11209": "飯能市",
    "11381": "児玉郡美里町",
    "14382": "足柄下郡箱根町",
    "26102": "京都市上京区",
    "12203": "市川市",
    "27214": "富田林市",
    "24343": "三重郡朝日町",
    "28223": "丹波市",
    "22103": "静岡市清水区",
    "16202": "高岡市",
    "23211": "豊田市",
    "26364": "相楽郡笠置町",
    "08226": "那珂市",
    "13111": "大田区",
    "23424": "海部郡大治町",
    "12208": "野田市",
    "41207": "鹿島市",
    "40384": "遠賀郡遠賀町",
    "32527": "隠岐郡知夫村",
    "13227": "羽村市",
    "28228": "加東市",
    "26100": "京都市",
    "29449": "吉野郡十津川村",
    "32525": "隠岐郡海士町",
    "13225": "稲城市",
    "22325": "田方郡函南町",
    "17206": "加賀市",
    "01585": "胆振総合振興局安平町",
    "43482": "葦北郡芦北町",
    "27106": "大阪市西区",
    "39204": "南国市",
    "01696": "根室振興局泊村",
    "06211": "東根市",
    "08234": "鉾田市",
    "35341": "熊毛郡上関町",
    "41205": "伊万里市",
    "20425": "木曽郡木祖村",
    "26210": "八幡市",
    "07213": "伊達市",
    "08224": "守谷市",
    "26366": "相楽郡精華町",
    "30208": "紀の川市",
    "29402": "高市郡明日香村",
    "07301": "伊達郡桑折町",
    "06426": "東田川郡三川町",
    "38214": "西予市",
    "11240": "幸手市",
    "02362": "南津軽郡大鰐町",
    "38356": "越智郡上島町",
    "27216": "河内長野市",
    "02405": "上北郡六戸町",
    "06363": "最上郡舟形町",
    "01343": "渡島総合振興局鹿部町",
    "10525": "邑楽郡邑楽町",
    "47308": "国頭郡本部町",
    "10344": "北群馬郡榛東村",
    "25210": "野洲市",
    "34214": "安芸高田市",
    "01560": "オホーツク総合振興局滝上町",
    "21501": "加茂郡坂祝町",
    "09209": "真岡市",
    "01223": "根室市",
    "34302": "安芸郡府中町",
    "20209": "伊那市",
    "01604": "日高振興局新冠町",
    "14402": "愛甲郡清川村",
    "07546": "双葉郡双葉町",
    "05363": "南秋田郡八郎潟町",
    "40131": "福岡市東区",
    "01664": "釧路総合振興局標茶町",
    "08219": "牛久市",
    "42210": "壱岐市",
    "46218": "霧島市",
    "34104": "広島市西区",
    "05303": "鹿角郡小坂町",
    "43216": "合志市",
    "01233": "伊達市",
    "33445": "浅口郡里庄町",
    "05211": "潟上市",
    "24562": "南牟婁郡紀宝町",
    "36341": "名西郡石井町",
    "20417": "下伊那郡大鹿村",
    "13105": "文京区",
    "01546": "オホーツク総合振興局清里町",
    "23205": "半田市",
    "39427": "幡多郡三原村",
    "44210": "杵築市",
    "33212": "瀬戸内市",
    "27226": "藤井寺市",
    "01642": "十勝総合振興局広尾町",
    "23207": "豊川市",
    "08302": "東茨城郡茨城町",
    "41424": "杵島郡江北町",
    "18208": "あわら市",
    "28108": "神戸市垂水区",
    "20415": "下伊那郡喬木村",
    "13107": "墨田区",
    "29208": "御所市",
    "08214": "高萩市",
    "33102": "岡山市中区",
    "23115": "名古屋市名東区",
    "20204": "岡谷市",
    "13215": "国立市",
    "01609": "日高振興局えりも町",
    "17461": "鳳珠郡穴水町",
    "19422": "南都留郡道志村",
    "01559": "オホーツク総合振興局湧別町",
    "28218": "小野市",
    "09204": "佐野市",
    "04104": "仙台市太白区",
    "11206": "行田市",
    "14118": "横浜市都筑区",
    "47205": "宜野湾市",
    "01430": "空知総合振興局月形町",
    "21421": "本巣郡北方町",
    "06341": "北村山郡大石田町",
    "35211": "長門市",
    "47207": "石垣市",
    "01361": "檜山振興局江差町",
    "04362": "亘理郡山元町",
    "01103": "札幌市東区",
    "08229": "稲敷市",
    "43501": "球磨郡錦町",
    "20448": "東筑摩郡生坂村",
    "04302": "刈田郡七ヶ宿町",
    "39209": "土佐清水市",
    "46502": "熊毛郡南種子町",
    "21216": "瑞穂市",
    "14208": "逗子市",
    "29444": "吉野郡黒滝村",
    "33643": "英田郡西粟倉村",
    "04214": "東松島市",
    "27140": "堺市",
    "28481": "赤穂郡上郡町",
    "01213": "苫小牧市",
    "12205": "館山市",
    "40642": "築上郡吉富町",
    "09364": "下都賀郡野木町",
    "33204": "玉野市",
    "28225": "朝来市",
    "10202": "高崎市",
    "34545": "神石郡神石高原町",
    "29424": "北葛城郡上牧町",
    "33623": "勝田郡奈義町",
    "28227": "宍粟市",
    "03322": "紫波郡矢巾町",
    "37201": "高松市",
    "27120": "大阪市住吉区",
    "13228": "あきる野市",
    "32528": "隠岐郡隠岐の島町",
    "41208": "小城市",
    "08202": "日立市",
    "12207": "松戸市",
    "20350": "小県郡長和町",
    "07466": "西白河郡矢吹町",
    "20304": "南佐久郡川上村",
    "46529": "大島郡喜界町",
    "32202": "浜田市",
    "22215": "御殿場市",
    "30205": "御坊市",
    "36203": "小松島市",
    "06321": "西村山郡河北町",
    "20212": "大町市",
    "30207": "新宮市",
    "24442": "多気郡明和町",
    "40609": "田川郡赤村",
    "44206": "臼杵市",
    "45404": "児湯郡木城町",
    "31384": "西伯郡日吉津村",
    "02408": "上北郡東北町",
    "40103": "北九州市若松区",
    "43513": "球磨郡球磨村",
    "28442": "神崎郡市川町",
    "20486": "北安曇郡小谷村",
    "35203": "山口市",
    "18483": "大飯郡おおい町",
    "19442": "北都留郡小菅村",
    "14135": "川崎市多摩区",
    "01632": "十勝総合振興局士幌町",
    "14137": "川崎市麻生区",
    "03209": "一関市",
    "46404": "出水郡長島町",
    "01571": "胆振総合振興局豊浦町",
    "11100": "さいたま市",
    "03381": "胆沢郡金ケ崎町",
    "31370": "東伯郡湯梨浜町",
    "06382": "東置賜郡川西町",
    "04202": "石巻市",
    "15226": "南魚沼市",
    "46215": "薩摩川内市",
    "40213": "行橋市",
    "11210": "加須市",
    "46217": "曽於市",
    "14153": "相模原市南区",
    "43403": "菊池郡大津町",
    "29207": "五條市",
    "13108": "江東区",
    "36321": "名東郡佐那河内村",
    "37406": "仲多度郡まんのう町",
    "06203": "鶴岡市",
    "28107": "神戸市須磨区",
    "18207": "鯖江市",
    "02202": "弘前市",
    "23208": "津島市",
    "12424": "長生郡白子町",
    "15385": "東蒲原郡阿賀町",
    "18205": "大野市",
    "39304": "安芸郡安田町",
    "39428": "幡多郡黒潮町",
    "26206": "亀岡市",
    "29205": "橿原市",
    "39212": "香美市",
    "44461": "玖珠郡九重町",
    "22225": "伊豆の国市",
    "28105": "神戸市兵庫区",
    "43441": "上益城郡御船町",
    "17210": "白山市",
    "30424": "東牟婁郡古座川町",
    "40343": "糟屋郡志免町",
    "39364": "土佐郡大川村",
    "28217": "川西市",
    "13218": "福生市",
    "15482": "中魚沼郡津南町",
    "28215": "三木市",
    "03507": "九戸郡洋野町",
    "28464": "揖保郡太子町",
    "38202": "今治市",
    "25443": "犬上郡多賀町",
    "07201": "福島市",
    "24215": "志摩市",
    "31389": "西伯郡南部町",
    "01516": "宗谷総合振興局豊富町",
    "31201": "鳥取市",
    "34202": "呉市",
    "47208": "浦添市",
    "01370": "檜山振興局今金町",
    "40604": "田川郡糸田町",
    "14115": "横浜市栄区",
    "40223": "古賀市",
    "14117": "横浜市青葉区",
    "46225": "姶良市",
    "15216": "糸魚川市",
    "20309": "南佐久郡佐久穂町",
    "46524": "大島郡宇検村",
    "04324": "柴田郡川崎町",
    "14205": "藤沢市",
    "46534": "大島郡知名町",
    "05203": "横手市",
    "35321": "玖珂郡和木町",
    "21403": "揖斐郡大野町",
    "10383": "甘楽郡南牧村",
    "11230": "新座市",
    "33209": "高梁市",
    "01452": "上川総合振興局鷹栖町",
    "25206": "草津市",
    "14207": "茅ヶ崎市",
    "01406": "後志総合振興局古平町",
    "45442": "西臼杵郡日之影町",
    "09342": "芳賀郡益子町",
    "15106": "新潟市南区",
    "01333": "渡島総合振興局知内町",
    "42204": "諫早市",
    "02441": "三戸郡三戸町",
    "01217": "江別市",
    "01464": "上川総合振興局和寒町",
    "46201": "鹿児島市",
    "01215": "美唄市",
    "01514": "宗谷総合振興局枝幸町",
    "44209": "豊後高田市",
    "11232": "久喜市",
    "01107": "札幌市西区",
    "35305": "大島郡周防大島町",
    "11222": "越谷市",
    "23446": "知多郡美浜町",
    "15104": "新潟市江南区",
    "01404": "後志総合振興局神恵内村",
    "25204": "近江八幡市",
    "43202": "八代市",
    "37386": "綾歌郡宇多津町",
    "40228": "朝倉市",
    "01105": "札幌市豊平区",
    "15405": "三島郡出雲崎町",
    "47311": "国頭郡恩納村",
    "01610": "日高振興局新ひだか町",
    "19384": "中巨摩郡昭和町",
    "40348": "糟屋郡久山町",
    "23113": "名古屋市守山区",
    "26204": "宇治市",
    "13213": "東村山市",
    "39306": "安芸郡馬路村",
    "17212": "野々市市",
    "44322": "東国東郡姫島村",
    "12426": "長生郡長柄町",
    "14341": "中郡大磯町",
    "11342": "比企郡嵐山町",
    "12101": "千葉市中央区",
    "37404": "仲多度郡多度津町",
    "21383": "安八郡安八町",
    "11369": "秩父郡東秩父村",
    "06208": "村山市",
    "13103": "港区",
    "13361": "大島支庁大島町",
    "23203": "一宮市",
    "27202": "岸和田市",
    "39210": "四万十市",
    "33461": "小田郡矢掛町",
    "45201": "宮崎市",
    "20563": "下高井郡野沢温泉村",
    "20220": "安曇野市",
    "03206": "北上市",
    "20413": "下伊那郡天龍村",
    "12211": "成田市",
    "36402": "板野郡北島町",
    "40218": "春日市",
    "46452": "姶良郡湧水町",
    "31372": "東伯郡北栄町",
    "27383": "南河内郡千早赤阪村",
    "31328": "八頭郡智頭町",
    "01235": "石狩市",
    "20541": "上高井郡小布施町",
    "30341": "伊都郡かつらぎ町",
    "11212": "東松山市",
    "14211": "秦野市",
    "25425": "愛知郡愛荘町",
    "40108": "北九州市八幡東区",
    "32441": "邑智郡川本町",
    "43468": "八代郡氷川町",
    "35208": "岩国市",
    "01225": "滝川市",
    "11102": "さいたま市北区",
    "14101": "横浜市鶴見区",
    "26209": "長岡京市",
    "15224": "佐渡市",
    "42322": "東彼杵郡川棚町",
    "01227": "歌志内市",
    "21202": "大垣市",
    "14363": "足柄上郡松田町",
    "24201": "津市",
    "12221": "八千代市",
    "07464": "西白河郡泉崎村",
    "20423": "木曽郡南木曽町",
    "40544": "八女郡広川町",
    "06367": "最上郡戸沢村",
    "20210": "駒ヶ根市",
    "29363": "磯城郡田原本町",
    "19201": "甲府市",
    "01347": "渡島総合振興局長万部町",
    "36208": "三好市",
    "28201": "姫路市",
    "29426": "北葛城郡広陵町",
    "47382": "八重山郡与那国町",
    "06402": "西置賜郡白鷹町",
    "25209": "甲賀市",
    "06365": "最上郡大蔵村",
    "10523": "邑楽郡千代田町",
    "03461": "上閉伊郡大槌町",
    "01409": "後志総合振興局赤井川村",
    "01345": "渡島総合振興局森町",
    "09210": "大田原市",
    "28111": "神戸市西区",
    "01519": "宗谷総合振興局利尻富士町",
    "31386": "西伯郡大山町",
    "45406": "児湯郡都農町",
    "29211": "葛城市",
    "44204": "日田市",
    "01469": "上川総合振興局美深町",
    "13223": "武蔵村山市",
    "22131": "浜松市中区",
    "27122": "大阪市西成区",
    "12231": "印西市",
    "42209": "対馬市",
    "26322": "久世郡久御山町",
    "41203": "鳥栖市",
    "20306": "南佐久郡南相木村",
    "27223": "門真市",
    "28214": "宝塚市",
    "09208": "小山市",
    "12322": "印旛郡酒々井町",
    "07501": "石川郡石川町",
    "23222": "東海市",
    "30427": "東牟婁郡北山村",
    "13122": "葛飾区",
    "19214": "中央市",
    "01543": "オホーツク総合振興局美幌町",
    "16323": "中新川郡立山町",
    "03207": "久慈市",
    "02412": "上北郡おいらせ町",
    "03205": "花巻市",
    "01482": "留萌振興局小平町",
    "20208": "小諸市",
    "39402": "高岡郡佐川町",
    "39305": "安芸郡北川村",
    "18204": "小浜市",
    "12427": "長生郡長南町",
    "46219": "いちき串木野市",
    "44211": "宇佐市",
    "29204": "天理市",
    "22224": "菊川市",
    "07561": "相馬郡新地町",
    "39307": "安芸郡芸西村",
    "01392": "後志総合振興局寿都町",
    "23232": "愛西市",
    "28229": "たつの市",
    "37387": "綾歌郡綾川町",
    "11203": "川口市",
    "23445": "知多郡南知多町",
    "21361": "不破郡垂井町",
    "27141": "堺市堺区",
    "23447": "知多郡武豊町",
    "04422": "黒川郡大郷町",
    "46535": "大島郡与論町",
    "14204": "鎌倉市",
    "01106": "札幌市南区",
    "22344": "駿東郡小山町",
    "30209": "岩出市",
    "30381": "日高郡美浜町",
    "46224": "伊佐市",
    "21213": "各務原市",
    "46525": "大島郡瀬戸内町",
    "01431": "空知総合振興局浦臼町",
    "22219": "下田市",
    "46527": "大島郡龍郷町",
    "35210": "光市",
    "24214": "いなべ市",
    "23342": "西春日井郡豊山町",
    "01216": "芦別市",
    "14114": "横浜市瀬谷区",
    "40605": "田川郡川崎町",
    "07421": "河沼郡会津坂下町",
    "26211": "京田辺市",
    "20307": "南佐久郡北相木村",
    "45405": "児湯郡川南町",
    "38422": "喜多郡内子町",
    "26303": "乙訓郡大山崎町",
    "08228": "坂東市",
    "23102": "名古屋市東区",
    "20305": "南佐久郡南牧村",
    "27103": "大阪市福島区",
    "22214": "藤枝市",
    "18322": "吉田郡永平寺町",
    "11241": "鶴ヶ島市",
    "01693": "根室振興局標津町",
    "22302": "賀茂郡河津町",
    "27361": "泉南郡熊取町",
    "30204": "有田市",
    "17203": "小松市",
    "29322": "山辺郡山添村",
    "13202": "立川市",
    "33207": "井原市",
    "29425": "北葛城郡王寺町",
    "16201": "富山市",
    "26101": "京都市北区",
    "20402": "下伊那郡松川町",
    "29453": "吉野郡東吉野村",
    "39208": "宿毛市",
    "27301": "三島郡島本町",
    "01346": "渡島総合振興局八雲町",
    "13112": "世田谷区",
    "06366": "最上郡鮭川村",
    "12204": "船橋市",
    "24344": "三重郡川越町",
    "28224": "南あわじ市",
    "33205": "笠岡市",
    "29427": "北葛城郡河合町",
    "27213": "泉佐野市",
    "23212": "安城市",
    "36489": "三好郡東みよし町",
    "06210": "天童市",
    "01226": "砂川市",
    "04606": "本吉郡南三陸町",
    "03483": "下閉伊郡岩泉町",
    "47350": "島尻郡南風原町",
    "21604": "大野郡白川村",
    "42211": "五島市",
    "47375": "宮古郡多良間村",
    "07482": "東白川郡矢祭町",
    "43103": "熊本市西区",
    "29209": "生駒市",
    "01668": "釧路総合振興局白糠町",
    "28109": "神戸市北区",
    "05366": "南秋田郡井川町",
    "18209": "越前市",
    "34108": "広島市佐伯区",
    "07543": "双葉郡富岡町",
    "05210": "由利本荘市",
    "46214": "垂水市",
    "01608": "日高振興局様似町",
    "44341": "速見郡日出町",
    "01561": "オホーツク総合振興局興部町",
    "25211": "湖南市",
    "28219": "三田市",
    "40130": "福岡市",
    "47212": "豊見城市",
    "01236": "北斗市",
    "14134": "川崎市高津区",
    "43213": "宇城市",
    "46216": "日置市",
    "15225": "魚沼市",
    "21502": "加茂郡富加町",
    "31302": "岩美郡岩美町",
    "34213": "廿日市市",
    "11365": "秩父郡小鹿野町",
    "47210": "糸満市",
    "40132": "福岡市博多区",
    "15227": "胎内市",
    "20382": "上伊那郡辰野町",
    "19346": "西八代郡市川三郷町",
    "14401": "愛甲郡愛川町",
    "01224": "千歳市",
    "14136": "川崎市宮前区",
    "01234": "北広島市",
    "20485": "北安曇郡白馬村",
    "12443": "夷隅郡御宿町",
    "43432": "阿蘇郡西原村",
    "47306": "国頭郡今帰仁村",
    "01663": "釧路総合振興局浜中町",
    "05212": "大仙市",
    "24561": "南牟婁郡御浜町",
    "36342": "名西郡神山町",
    "07548": "双葉郡葛尾村",
    "32343": "仁多郡奥出雲町",
    "42308": "西彼杵郡時津町",
    "34103": "広島市南区",
    "46392": "薩摩郡さつま町",
    "30206": "田辺市",
    "07405": "耶麻郡西会津町",
    "22216": "袋井市",
    "39203": "安芸市",
    "45421": "東臼杵郡門川町",
    "07362": "南会津郡下郷町",
    "44207": "津久見市",
    "02301": "東津軽郡平内町",
    "08233": "行方市",
    "23210": "刈谷市",
    "06212": "尾花沢市",
    "44205": "佐伯市",
    "20321": "北佐久郡軽井沢町",
    "40383": "遠賀郡岡垣町",
    "06428": "東田川郡庄内町",
    "27218": "大東市",
    "13110": "目黒区",
    "01219": "紋別市",
    "07407": "耶麻郡磐梯町",
    "38213": "四国中央市",
    "28226": "淡路市",
    "46482": "肝属郡東串良町",
    "29401": "高市郡高取町",
    "12206": "木更津市",
    "06364": "最上郡真室川町",
    "03211": "釜石市",
    "01698": "根室振興局留別村",
    "45431": "東臼杵郡美郷町",
    "28501": "佐用郡佐用町",
    "01109": "札幌市手稲区",
    "07465": "西白河郡中島村",
    "27108": "大阪市大正区",
    "08223": "潮来市",
    "07214": "本宮市",
    "31390": "西伯郡伯耆町",
    "02361": "南津軽郡藤崎町",
    "03303": "岩手郡岩手町",
    "23100": "名古屋市",
    "25207": "守山市",
    "01104": "札幌市白石区",
    "01407": "後志総合振興局仁木町",
    "14206": "小田原市",
    "15107": "新潟市西区",
    "21218": "本巣市",
    "04103": "仙台市若林区",
    "02343": "中津軽郡西目屋村",
    "01405": "後志総合振興局積丹町",
    "15105": "新潟市秋葉区",
    "35212": "柳井市",
    "01362": "檜山振興局上ノ国町",
    "04361": "亘理郡亘理町",
    "01465": "上川総合振興局剣淵町",
    "14116": "横浜市泉区",
    "15217": "妙高市",
    "01214": "稚内市",
    "42207": "平戸市",
    "04301": "刈田郡蔵王町",
    "46501": "熊毛郡中種子町",
    "24216": "伊賀市",
    "11208": "所沢市",
    "01517": "宗谷総合振興局礼文町",
    "42205": "大村市",
    "20446": "東筑摩郡麻績村",
    "40202": "大牟田市",
    "29443": "吉野郡下市町",
    "40621": "京都郡苅田町",
    "08546": "猿島郡境町",
    "07344": "岩瀬郡天栄村",
    "04213": "栗原市",
    "41210": "神埼市",
    "17365": "河北郡内灘町",
    "33211": "備前市",
    "03506": "九戸郡九戸村",
    "41423": "杵島郡大町町",
    "43348": "下益城郡美里町",
    "23230": "日進市",
    "30401": "西牟婁郡白浜町",
    "01641": "十勝総合振興局大樹町",
    "28216": "高砂市",
    "24543": "北牟婁郡紀北町",
    "23563": "北設楽郡豊根村",
    "26207": "城陽市",
    "01229": "富良野市",
    "09203": "栃木市",
    "13120": "練馬区",
    "27228": "泉南市",
    "33101": "岡山市北区",
    "23220": "稲沢市",
    "28106": "神戸市長田区",
    "22226": "牧之原市",
    "29206": "桜井市",
    "20203": "上田市",
    "26205": "宮津市",
    "18206": "勝山市",
    "21504": "加茂郡七宗町",
    "11105": "さいたま市中央区",
    "05349": "山本郡八峰町",
    "09384": "塩谷郡塩谷町",
    "34211": "大竹市",
    "21205": "関市",
    "14130": "川崎市",
    "43424": "阿蘇郡小国町",
    "21207": "美濃市",
    "40134": "福岡市南区",
    "11107": "さいたま市浦和区",
    "20384": "上伊那郡飯島町",
    "01601": "日高振興局日高町",
    "08341": "那珂郡東海村",
    "01222": "三笠市",
    "43368": "玉名郡長洲町",
    "47354": "島尻郡座間味村",
    "34101": "広島市中区",
    "11464": "北葛飾郡杉戸町",
    "01661": "釧路総合振興局釧路町",
    "47360": "島尻郡伊是名村",
    "11215": "狭山市",
    "31364": "東伯郡三朝町",
    "05214": "にかほ市",
    "46210": "指宿市",
    "11217": "鴻巣市",
    "15208": "小千谷市",
    "12441": "夷隅郡大多喜町",
    "41346": "三養基郡みやき町",
    "38488": "北宇和郡鬼北町",
    "15581": "岩船郡関川村",
    "40381": "遠賀郡芦屋町",
    "13206": "府中市",
    "22306": "賀茂郡西伊豆町",
    "27125": "大阪市住之江区",
    "07364": "南会津郡檜枝岐村",
    "20323": "北佐久郡御代田町",
    "23106": "名古屋市中区",
    "39201": "高知市",
    "27127": "大阪市北区",
    "42391": "北松浦郡佐々町",
    "02303": "東津軽郡今別町",
    "22100": "静岡市",
    "28220": "加西市",
    "08231": "桜川市",
    "35344": "熊毛郡平生町",
    "26108": "京都市右京区",
    "16208": "砺波市",
    "06302": "東村山郡中山町",
    "23216": "常滑市",
    "03301": "岩手郡雫石町",
    "06362": "最上郡最上町",
    "02426": "下北郡佐井村",
    "13116": "豊島区",
    "04341": "伊具郡丸森町",
    "08221": "ひたちなか市",
    "03213": "二戸市",
    "07212": "南相馬市",
    "22210": "富士市",
    "20452": "東筑摩郡筑北村",
    "07445": "大沼郡金山町",
    "46220": "南さつま市",
    "01102": "札幌市北区",
    "04101": "仙台市青葉区",
    "11227": "朝霞市",
    "14110": "横浜市戸塚区",
    "24470": "度会郡度会町",
    "11225": "入間市",
    "01438": "空知総合振興局沼田町",
    "43205": "水俣市",
    "20588": "上水内郡小川村",
    "07447": "大沼郡会津美里町",
    "24210": "亀山市",
    "01364": "檜山振興局乙部町",
    "29441": "吉野郡吉野町",
    "01212": "留萌市",
    "04211": "岩沼市",
    "17386": "羽咋郡宝達志水町",
    "01304": "石狩振興局新篠津村",
    "15307": "北蒲原郡聖籠町",
    "01428": "空知総合振興局長沼町",
    "01472": "上川総合振興局幌加内町",
    "11235": "富士見市",
    "11237": "三郷市",
    "41387": "東松浦郡玄海町",
    "40204": "直方市",
    "07342": "岩瀬郡鏡石町",
    "28100": "神戸市",
    "22220": "裾野市",
    "01643": "十勝総合振興局幕別町",
    "10211": "安中市",
    "01486": "留萌振興局遠別町",
    "27207": "高槻市",
    "36405": "板野郡上板町",
    "40448": "朝倉郡東峰村",
    "23501": "額田郡幸田町",
    "13401": "八丈支庁八丈町",
    "08211": "常総市",
    "38506": "南宇和郡愛南町",
    "12463": "安房郡鋸南町",
    "23226": "尾張旭市",
    "11442": "南埼玉郡宮代町",
    "47359": "島尻郡伊平屋村",
    "33213": "赤磐市",
    "27205": "吹田市",
    "12403": "山武郡九十九里町",
    "23236": "みよし市",
    "37364": "香川郡直島町",
    "20201": "長野市",
    "01396": "後志総合振興局真狩村",
    "19210": "甲斐市",
    "19423": "南都留郡西桂町",
    "10426": "吾妻郡草津町",
    "27117": "大阪市旭区",
    "19366": "南巨摩郡南部町",
    "28210": "加古川市",
    "33103": "岡山市東区",
    "27115": "大阪市東成区",
    "36302": "勝浦郡上勝町",
    "23561": "北設楽郡設楽町",
    "11347": "比企郡吉見町",
    "09201": "宇都宮市",
    "28212": "赤穂市",
    "38205": "新居浜市",
    "23224": "知多市",
    "19212": "上野原市",
    "16321": "中新川郡舟橋村",
    "27221": "柏原市",
    "01484": "留萌振興局羽幌町",
    "38207": "大洲市",
    "07503": "石川郡平田村",
    "27231": "大阪狭山市",
    "18202": "敦賀市",
    "01648": "十勝総合振興局陸別町",
    "05346": "山本郡藤里町",
    "02207": "三沢市",
    "29202": "大和高田市",
    "28102": "神戸市灘区",
    "22222": "伊豆市",
    "44213": "由布市",
    "19364": "南巨摩郡早川町",
    "10424": "吾妻郡長野原町",
    "01394": "後志総合振興局蘭越町",
    "23234": "北名古屋市",
    "02205": "五所川原市",
    "01470": "上川総合振興局音威子府村",
    "35216": "山陽小野田市",
    "01455": "上川総合振興局比布町",
    "23219": "小牧市",
    "13119": "板橋区",
    "01210": "岩見沢市",
    "43506": "球磨郡湯前町",
    "09345": "芳賀郡芳賀町",
    "01423": "空知総合振興局南幌町",
    "04424": "黒川郡大衡村",
    "27143": "堺市東区",
    "11201": "川越市",
    "26343": "綴喜郡井手町",
    "20409": "下伊那郡平谷村",
    "01457": "上川総合振興局上川町",
    "22342": "駿東郡長泉町",
    "08542": "猿島郡五霞町",
    "36387": "海部郡美波町",
    "13381": "三宅支庁三宅村",
    "46222": "奄美市",
    "40206": "田川市",
    "13209": "町田市",
    "21303": "羽島郡笠松町",
    "01100": "札幌市",
    "23109": "名古屋市熱田区",
    "27341": "泉北郡忠岡町",
    "34207": "福山市",
    "01433": "空知総合振興局妹背牛町",
    "24212": "熊野市",
    "21211": "美濃加茂市",
    "34205": "尾道市",
    "39386": "吾川郡いの町",
    "17384": "羽咋郡志賀町",
    "30383": "日高郡由良町",
    "24472": "度会郡南伊勢町",
    "20583": "上水内郡信濃町",
    "14112": "横浜市旭区",
    "17201": "金沢市",
    "09215": "那須烏山市",
    "20217": "佐久市",
    "23362": "丹羽郡扶桑町",
    "32207": "江津市",
    "11243": "吉川市",
    "01691": "根室振興局別海町",
    "20450": "東筑摩郡山形村",
    "32205": "大田市",
    "08564": "北相馬郡利根町",
    "23104": "名古屋市西区",
    "22212": "焼津市",
    "33666": "久米郡美咲町",
    "22304": "賀茂郡南伊豆町",
    "07423": "河沼郡柳津町",
    "07210": "二本松市",
    "30202": "海南市",
    "20215": "塩尻市",
    "13204": "三鷹市",
    "26213": "南丹市",
    "27211": "茨木市",
    "33606": "苫田郡鏡野町",
    "40647": "築上郡築上町",
    "10207": "館林市",
    "08205": "石岡市",
    "20404": "下伊那郡阿南町",
    "14383": "足柄下郡真鶴町",
    "26103": "京都市左京区",
    "13114": "中野区",
    "23302": "愛知郡東郷町",
    "08207": "結城市",
    "02424": "下北郡東通村",
    "29451": "吉野郡上北山村",
    "12202": "銚子市",
    "21341": "養老郡養老町",
    "22102": "静岡市駿河区",
    "28222": "養父市",
    "10205": "太田市",
    "23214": "蒲郡市",
    "07541": "双葉郡広野町",
    "47362": "島尻郡八重瀬町",
    "01399": "後志総合振興局京極町",
    "04207": "名取市",
    "20386": "上伊那郡中川村",
    "43101": "熊本市中央区",
    "21221": "海津市",
    "01230": "登別市",
    "40136": "福岡市城南区",
    "10429": "吾妻郡東吾妻町",
    "34368": "山県郡安芸太田町",
    "07484": "東白川郡鮫川村",
    "46304": "鹿児島郡十島村",
    "42213": "雲仙市",
    "09386": "塩谷郡高根沢町",
    "04205": "気仙沼市",
    "21506": "加茂郡白川町",
    "01635": "十勝総合振興局新得町",
    "43211": "宇土市",
    "01220": "士別市",
    "47302": "国頭郡大宜味村",
    "40401": "鞍手郡小竹町",
    "01563": "オホーツク総合振興局雄武町",
    "12329": "印旛郡栄町",
    "47214": "宮古島市",
    "47356": "島尻郡渡名喜村",
    "01637": "十勝総合振興局芽室町",
    "23229": "豊明市",
    "14132": "川崎市幸区",
    "25213": "東近江市",
    "42202": "佐世保市",
    "39387": "吾川郡仁淀川町",
    "46203": "鹿屋市",
    "15212": "村上市",
    "40205": "飯塚市",
    "46530": "大島郡徳之島町",
    "40207": "柳川市",
    "01462": "上川総合振興局南富良野町",
    "01512": "宗谷総合振興局浜頓別町",
    "02443": "三戸郡田子町",
    "11234": "八潮市",
    "47313": "国頭郡宜野座村",
    "11224": "戸田市",
    "01402": "後志総合振興局岩内町",
    "43505": "球磨郡多良木町",
    "04401": "宮城郡松島町",
    "15102": "新潟市東区",
    "01456": "上川総合振興局愛別町",
    "25202": "彦根市",
    "43204": "荒尾市",
    "03441": "気仙郡住田町",
    "35215": "周南市",
    "07444": "大沼郡三島町",
    "01367": "檜山振興局奥尻町",
    "29343": "生駒郡三郷町",
    "47201": "那覇市",
    "28382": "加古郡播磨町",
    "43507": "球磨郡水上村",
    "22203": "沼津市",
    "27114": "大阪市東淀川区",
    "13303": "西多摩郡瑞穂町",
    "21209": "羽島市",
    "21381": "安八郡神戸町",
    "07208": "喜多方市",
    "26202": "舞鶴市",
    "11109": "さいたま市緑区",
    "12103": "千葉市稲毛区",
    "13211": "小平市",
    "02206": "十和田市",
    "23111": "名古屋市港区",
    "12213": "東金市",
    "20411": "下伊那郡下條村",
    "11219": "上尾市",
    "17407": "鹿島郡中能登町",
    "38206": "西条市",
    "45203": "延岡市",
    "20561": "下高井郡山ノ内町",
    "30361": "有田郡湯浅町",
    "27204": "池田市",
    "23201": "豊橋市",
    "13363": "大島支庁新島村",
    "07522": "田村郡小野町",
    "09411": "那須郡那珂川町",
    "36404": "板野郡板野町",
    "13101": "千代田区",
    "05215": "仙北市",
    "20543": "上高井郡高山村",
    "14213": "大和市",
    "05464": "雄勝郡東成瀬村",
    "30343": "伊都郡九度山町",
    "08443": "稲敷郡阿見町",
    "31402": "日野郡日野町",
    "45382": "東諸県郡国富町",
    "47357": "島尻郡南大東村",
    "01636": "十勝総合振興局清水町",
    "14301": "三浦郡葉山町",
    "01208": "北見市",
    "27381": "南河内郡太子町",
    "27209": "守口市",
    "47355": "島尻郡粟国村",
    "20482": "北安曇郡松川村",
    "11465": "北葛飾郡松伏町",
    "28446": "神崎郡神河町",
    "10443": "利根郡片品村",
    "11214": "春日部市",
    "01578": "胆振総合振興局白老町",
    "43425": "阿蘇郡産山村",
    "16342": "下新川郡入善町",
    "24203": "伊勢市",
    "11362": "秩父郡皆野町",
    "14361": "足柄上郡中井町",
    "40135": "福岡市西区",
    "20385": "上伊那郡南箕輪村",
    "14103": "横浜市西区",
    "21507": "加茂郡東白川村",
    "11349": "比企郡ときがわ町",
    "11104": "さいたま市見沼区",
    "15222": "上越市",
    "21505": "加茂郡八百津町",
    "04206": "白石市",
    "27119": "大阪市阿倍野区",
    "40137": "福岡市早良区",
    "21204": "多治見市",
    "15342": "西蒲原郡弥彦村",
    "02401": "上北郡野辺地町",
    "10521": "邑楽郡板倉町",
    "25383": "蒲生郡日野町",
    "46492": "肝属郡肝付町",
    "10206": "沼田市",
    "08521": "結城郡八千代町",
    "39411": "高岡郡津野町",
    "28203": "明石市",
    "40646": "築上郡上毛町",
    "12410": "山武郡横芝光町",
    "27322": "豊能郡能勢町",
    "12223": "鴨川市",
    "11229": "和光市",
    "29361": "磯城郡川西町",
    "12233": "富里市",
    "42383": "北松浦郡小値賀町",
    "11239": "坂戸市",
    "44202": "別府市",
    "41201": "佐賀市",
    "07367": "南会津郡只見町",
    "37208": "三豊市",
    "13221": "清瀬市",
    "09216": "下野市",
    "01581": "胆振総合振興局厚真町",
    "22133": "浜松市西区",
    "07402": "耶麻郡北塩原村",
    "38401": "伊予郡松前町",
    "27124": "大阪市鶴見区",
    "08310": "東茨城郡城里町",
    "32206": "安来市",
    "13115": "杉並区",
    "20407": "下伊那郡阿智村",
    "12238": "いすみ市",
    "02425": "下北郡風間浦村",
    "33202": "倉敷市",
    "01459": "上川総合振興局美瑛町",
    "37203": "坂出市",
    "23215": "犬山市",
    "10204": "伊勢崎市",
    "20362": "諏訪郡富士見町",
    "23217": "江南市",
    "08204": "古河市",
    "11385": "児玉郡上里町",
    "13117": "北区",
    "09407": "那須郡那須町",
    "20349": "小県郡青木村",
    "46490": "肝属郡錦江町",
    "23105": "名古屋市中村区",
    "32204": "益田市",
    "27126": "大阪市平野区",
    "26463": "与謝郡伊根町",
    "22305": "賀茂郡松崎町",
    "32505": "鹿足郡吉賀町",
    "20214": "茅野市",
    "13205": "青梅市",
    "06323": "西村山郡朝日町",
    "36201": "徳島市",
    "09214": "さくら市",
    "28208": "相生市",
    "13207": "昭島市",
    "19208": "南アルプス市",
    "23107": "名古屋市昭和区",
    "34209": "三次市",
    "45402": "児湯郡新富町",
    "12228": "四街道市",
    "11216": "羽生市",
    "24208": "名張市",
    "47215": "南城市",
    "14108": "横浜市金沢区",
    "01520": "宗谷総合振興局幌延町",
    "01634": "十勝総合振興局鹿追町",
    "41345": "三養基郡上峰町",
    "35201": "下関市",
    "33586": "真庭郡新庄村",
    "32448": "邑智郡美郷町",
    "18481": "大飯郡高浜町",
    "39344": "長岡郡大豊町",
    "43511": "球磨郡五木村",
    "40101": "北九州市門司区",
    "14151": "相模原市緑区",
    "21206": "中津川市",
    "14218": "綾瀬市",
    "04505": "遠田郡美里町",
    "11106": "さいたま市桜区",
    "01203": "小樽市",
    "40211": "筑後市",
    "02381": "北津軽郡板柳町",
    "21521": "可児郡御嵩町",
    "02209": "つがる市",
    "10448": "利根郡昭和村",
    "47328": "中頭郡中城村",
    "19365": "南巨摩郡身延町",
    "10425": "吾妻郡嬬恋村",
    "16210": "南砺市",
    "12218": "勝浦市",
    "01395": "後志総合振興局ニセコ町",
    "11346": "比企郡川島町",
    "28365": "多可郡多可町",
    "26110": "京都市山科区",
    "02204": "黒石市",
    "23235": "弥富市",
    "12422": "長生郡睦沢町",
    "45208": "西都市",
    "01397": "後志総合振興局留寿都村",
    "39302": "安芸郡奈半利町",
    "04209": "多賀城市",
    "23237": "あま市",
    "27116": "大阪市生野区",
    "06201": "山形市",
    "07203": "郡山市",
    "25441": "犬上郡豊郷町",
    "30422": "東牟婁郡太地町",
    "13308": "西多摩郡奥多摩町",
    "22208": "伊東市",
    "03202": "宮古市",
    "01639": "十勝総合振興局更別村",
    "23227": "高浜市",
    "39405": "高岡郡檮原町",
    "01485": "留萌振興局初山別村",
    "27206": "泉大津市",
    "23225": "知立市",
    "38204": "八幡浜市",
    "01487": "留萌振興局天塩町",
    "43443": "上益城郡益城町",
    "40341": "糟屋郡宇美町",
    "34204": "三原市",
    "30391": "日高郡みなべ町",
    "15100": "新潟市",
    "01400": "後志総合振興局倶知安町",
    "40602": "田川郡添田町",
    "40221": "太宰府市",
    "20219": "東御市",
    "43531": "天草郡苓北町",
    "32209": "雲南市",
    "04322": "柴田郡村田町",
    "31203": "倉吉市",
    "46532": "大島郡伊仙町",
    "15210": "十日町市",
    "01331": "渡島総合振興局松前町",
    "46208": "出水市",
    "07446": "大沼郡昭和村",
    "21401": "揖斐郡揖斐川町",
    "40231": "那珂川市",
    "43206": "玉名市",
    "10209": "藤岡市",
    "01454": "上川総合振興局当麻町",
    "09344": "芳賀郡市貝町",
    "23442": "知多郡東浦町",
    "01460": "上川総合振興局上富良野町",
    "05201": "秋田市",
    "12235": "匝瑳市",
    "11246": "白岡市",
    "06213": "南陽市",
    "35343": "熊毛郡田布施町",
    "27210": "枚方市",
    "13118": "荒川区",
    "02304": "東津軽郡蓬田村",
    "40382": "遠賀郡水巻町",
    "27366": "泉南郡岬町",
    "22135": "浜松市北区",
    "29450": "吉野郡下北山村",
    "06301": "東村山郡山辺町",
    "22137": "浜松市天竜区",
    "43484": "葦北郡津奈木町",
    "12237": "山武市",
    "39202": "室戸市",
    "04445": "加美郡加美町",
    "33663": "久米郡久米南町",
    "08232": "神栖市",
    "20324": "北佐久郡立科町",
    "06361": "最上郡金山町",
    "12227": "浦安市",
    "03214": "八幡平市",
    "18404": "南条郡南越前町",
    "23108": "名古屋市瑞穂区",
    "19207": "韮崎市",
    "08222": "鹿嶋市",
    "13208": "調布市",
    "22424": "榛原郡吉田町",
    "07303": "伊達郡国見町",
    "27100": "大阪市",
    "28207": "伊丹市",
    "03302": "岩手郡葛巻町",
    "16206": "滑川市",
    "28205": "洲本市",
    "20451": "東筑摩郡朝日村",
    "12225": "君津市",
    "26106": "京都市下京区",
    "07211": "田村市",
    "19205": "山梨市",
    "43423": "阿蘇郡南小国町",
    "05361": "南秋田郡五城目町",
    "34212": "東広島市",
    "43210": "菊池市",
    "40133": "福岡市中央区",
    "24205": "桑名市",
    "29386": "宇陀郡御杖村",
    "20383": "上伊那郡箕輪町",
    "12409": "山武郡芝山町",
    "14105": "横浜市南区",
    "34304": "安芸郡海田町",
    "01552": "オホーツク総合振興局佐呂間町",
    "19429": "南都留郡鳴沢村",
    "21503": "加茂郡川辺町",
    "15206": "新発田市",
    "12347": "香取郡多古町",
    "14107": "横浜市磯子区",
    "01602": "日高振興局平取町",
    "01221": "名寄市",
    "37341": "木田郡三木町",
    "24207": "鈴鹿市",
    "47327": "中頭郡北中城村",
    "05213": "北秋田市",
    "01662": "釧路総合振興局厚岸町",
    "14215": "海老名市",
    "08309": "東茨城郡大洗町",
    "01649": "十勝総合振興局浦幌町",
    "34102": "広島市東区",
    "21220": "下呂市",
    "43100": "熊本市",
    "01231": "恵庭市",
    "08447": "稲敷郡河内町",
    "11326": "入間郡毛呂山町",
    "47353": "島尻郡渡嘉敷村",
    "47325": "中頭郡嘉手納町",
    "43433": "阿蘇郡南阿蘇村",
    "14217": "南足柄市",
    "12215": "旭市",
    "10428": "吾妻郡高山村",
    "34369": "山県郡北広島町",
    "27230": "交野市",
    "47348": "島尻郡与那原町",
    "19368": "南巨摩郡富士川町",
    "45205": "小林市",
    "23238": "長久手市",
    "10212": "みどり市",
    "33214": "真庭市",
    "01398": "後志総合振興局喜茂別町",
    "01644": "十勝総合振興局池田町",
    "45207": "串間市",
    "12217": "柏市",
    "13402": "八丈支庁青ヶ島村",
    "08212": "常陸太田市",
    "30404": "西牟婁郡上富田町",
    "22205": "熱海市",
    "01700": "根室振興局蘂取村",
    "33104": "岡山市南区",
    "13305": "西多摩郡日の出町",
    "08364": "久慈郡大子町",
    "17463": "鳳珠郡能登町",
    "20202": "松本市",
    "12105": "千葉市緑区",
    "23562": "北設楽郡東栄町",
    "23228": "岩倉市",
    "41327": "神埼郡吉野ヶ里町",
    "19424": "南都留郡忍野村",
    "34309": "安芸郡坂町",
    "09202": "足利市",
    "22207": "富士宮市",
    "36301": "勝浦郡勝浦町",
    "13307": "西多摩郡檜原村",
    "27220": "箕面市",
    "01101": "札幌市中央区",
    "47315": "国頭郡伊江村",
    "04102": "仙台市宮城野区",
    "42411": "南松浦郡新上五島町",
    "01363": "檜山振興局厚沢部町",
    "35213": "美祢市",
    "22429": "榛原郡川根本町",
    "29345": "生駒郡安堵町",
    "21210": "恵那市",
    "11110": "さいたま市岩槻区",
    "27146": "堺市北区",
    "18442": "三方郡美浜町",
    "01471": "上川総合振興局中川町",
    "40203": "久留米市",
    "29442": "吉野郡大淀町",
    "01211": "網走市",
    "04212": "登米市",
    "15461": "南魚沼郡湯沢町",
    "01303": "石狩振興局当別町",
    "45429": "東臼杵郡諸塚村",
    "01436": "空知総合振興局雨竜町",
    "02445": "三戸郡南部町",
    "27144": "堺市西区",
    "26344": "綴喜郡宇治田原町",
    "22341": "駿東郡清水町",
    "16209": "小矢部市",
    "26109": "京都市伏見区",
    "01424": "空知総合振興局奈井江町",
    "14201": "横須賀市",
    "11202": "熊谷市",
    "01434": "空知総合振興局秩父別町",
    "13382": "三宅支庁御蔵島村",
    "46221": "志布志市",
    "04100": "仙台市",
    "24303": "桑名郡木曽岬町",
    "24471": "度会郡大紀町",
    "14111": "横浜市港南区",
    "40522": "三潴郡大木町",
    "24211": "鳥羽市",
    "21212": "土岐市",
    "45341": "北諸県郡三股町",
    "07205": "白河市",
    "19211": "笛吹市",
    "43447": "上益城郡山都町",
    "30406": "西牟婁郡すさみ町",
    "39403": "高岡郡越知町",
    "07504": "石川郡浅川町",
    "05348": "山本郡三種町",
    "01483": "留萌振興局苫前町",
    "01646": "十勝総合振興局本別町",
    "03501": "九戸郡軽米町",
    "33216": "浅口市",
    "27222": "羽曳野市",
    "23223": "大府市",
    "07207": "須賀川市",
    "16322": "中新川郡上市町",
    "13123": "江戸川区",
    "44214": "国東市",
    "29201": "奈良市",
    "28101": "神戸市東灘区",
    "22221": "湖西市",
    "10210": "富岡市",
    "01393": "後志総合振興局黒松内町",
    "27232": "阪南市",
    "18201": "福井市",
    "23233": "清須市",
    "43369": "玉名郡和水町",
    "10366": "多野郡上野村",
    "08210": "下妻市",
    "15209": "加茂市",
    "07564": "相馬郡飯舘村",
    "17324": "能美郡川北町",
    "43102": "熊本市東区",
    "34100": "広島市",
    "46303": "鹿児島郡三島村",
    "42214": "南島原市",
    "07542": "双葉郡楢葉町",
    "47361": "島尻郡久米島町",
    "01207": "帯広市",
    "38484": "北宇和郡松野町",
    "15204": "三条市",
    "47358": "島尻郡北大東村",
    "01205": "室蘭市",
    "43364": "玉名郡玉東町",
    "33346": "和気郡和気町",
    "03482": "下閉伊郡山田町",
    "07483": "東白川郡塙町",
    "01550": "オホーツク総合振興局置戸町",
    "35502": "阿武郡阿武町",
    "01575": "胆振総合振興局壮瞥町",
    "43428": "阿蘇郡高森町",
    "05327": "北秋田郡上小阿仁村",
    "47213": "うるま市",
    "11408": "大里郡寄居町",
    "01564": "オホーツク総合振興局大空町",
    "25214": "米原市",
    "11324": "入間郡三芳町",
    "43212": "上天草市",
    "34210": "庄原市",
    "20388": "上伊那郡宮田村",
    "14131": "川崎市川崎区",
    "47301": "国頭郡国頭村",
    "40402": "鞍手郡鞍手町",
    "23103": "名古屋市北区",
    "01692": "根室振興局中標津町",
    "27102": "大阪市都島区",
    "26465": "与謝郡与謝野町",
    "17202": "七尾市",
    "08220": "つくば市",
    "26214": "木津川市",
    "07308": "伊達郡川俣町",
    "13203": "武蔵野市",
    "23361": "丹羽郡大口町",
    "30201": "和歌山市",
    "22211": "磐田市",
    "38210": "伊予市",
    "13113": "渋谷区",
    "26104": "京都市中京区",
    "14384": "足柄下郡湯河原町",
    "01429": "空知総合振興局栗山町",
    "02210": "平川市",
    "26407": "船井郡京丹波町",
    "02423": "下北郡大間町",
    "27212": "八尾市",
    "16204": "魚津市",
    "23213": "西尾市",
    "37205": "観音寺市",
    "22101": "静岡市葵区",
    "28221": "丹波篠山市",
    "08230": "かすみがうら市",
    "24341": "三重郡菰野町",
    "37207": "東かがわ市",
    "07368": "南会津郡南会津町",
    "11383": "児玉郡神川町",
    "03216": "滝沢市",
    "29452": "吉野郡川上村",
    "20403": "下伊那郡高森町",
    "26201": "福知山市",
    "14109": "横浜市港北区",
    "12349": "香取郡東庄町",
    "19425": "南都留郡山中湖村",
    "11343": "比企郡小川町",
    "24209": "尾鷲市",
    "21382": "安八郡輪之内町",
    "27113": "大阪市西淀川区",
    "23112": "名古屋市南区",
    "13212": "日野市",
    "10367": "多野郡神流町",
    "32449": "邑智郡邑南町",
    "12104": "千葉市若葉区",
    "16211": "射水市",
    "20562": "下高井郡木島平村",
    "13364": "大島支庁神津島村",
    "01645": "十勝総合振興局豊頃町",
    "30362": "有田郡広川町",
    "03524": "二戸郡一戸町",
    "20412": "下伊那郡売木村",
    "36403": "板野郡藍住町",
    "26111": "京都市西京区",
    "47329": "中頭郡西原町",
    "10449": "利根郡みなかみ町",
    "13102": "中央区",
    "23202": "岡崎市",
    "02208": "むつ市",
    "45204": "日南市",
    "07521": "田村郡三春町",
    "01647": "十勝総合振興局足寄町",
    "27203": "豊中市",
    "33215": "美作市",
    "17361": "河北郡津幡町",
    "40625": "京都郡みやこ町",
    "15211": "見附市",
    "01458": "上川総合振興局東川町",
    "42201": "長崎市",
    "12239": "大網白里市",
    "11233": "北本市",
    "40230": "糸島市",
    "01511": "宗谷総合振興局猿払村",
    "40503": "三井郡大刀洗町",
    "46505": "熊毛郡屋久島町",
    "46204": "枕崎市",
    "01461": "上川総合振興局中富良野町",
    "25201": "大津市",
    "29344": "生駒郡斑鳩町",
    "20590": "上水内郡飯綱町",
    "40220": "宗像市",
    "30390": "日高郡印南町",
    "01401": "後志総合振興局共和町",
    "15101": "新潟市北区",
    "47314": "国頭郡金武町",
    "34208": "府中市",
    "12229": "袖ケ浦市",
    "19209": "北杜市",
    "11223": "蕨市",
    "43203": "人吉市",
    "36388": "海部郡海陽町",
    "28209": "豊岡市",
    "28381": "加古郡稲美町",
    "37206": "さぬき市",
    "06403": "西置賜郡飯豊町",
    "46491": "肝属郡南大隅町",
    "28204": "西宮市",
    "07461": "西白河郡西郷村",
    "12224": "鎌ケ谷市",
    "02402": "上北郡七戸町",
    "19204": "都留市",
    "10522": "邑楽郡明和町",
    "03215": "奥州市",
    "27321": "豊能郡豊能町",
    "20422": "木曽郡上松町",
    "29362": "磯城郡三宅町",
    "25384": "蒲生郡竜王町",
    "02367": "南津軽郡田舎館村",
    "39412": "高岡郡四万十町",
    "20218": "千曲市",
    "13222": "東久留米市",
    "04581": "牡鹿郡女川町",
    "20432": "木曽郡木曽町",
    "04444": "加美郡色麻町",
    "27123": "大阪市淀川区",
    "02307": "東津軽郡外ヶ浜町",
    "44201": "大分市",
    "08208": "龍ケ崎市",
    "41202": "唐津市",
    "38402": "伊予郡砥部町",
    "12234": "南房総市",
    "10208": "渋川市",
    "22134": "浜松市南区",
    "31401": "日野郡日南町",
    "45209": "えびの市",
    "14150": "相模原市",
    "12219": "市原市",
    "47324": "中頭郡読谷村",
    "10444": "利根郡川場村",
    "40210": "八女市",
    "30344": "伊都郡高野町",
    "14214": "伊勢原市",
    "05463": "雄勝郡羽後町",
    "11301": "北足立郡伊奈町",
    "27382": "南河内郡河南町",
    "04208": "角田市",
    "20481": "北安曇郡池田町",
    "11103": "さいたま市大宮区",
    "01638": "十勝総合振興局中札内村",
    "42323": "東彼杵郡波佐見町",
    "34307": "安芸郡熊野町",
    "22209": "島田市",
    "11361": "秩父郡横瀬町",
    "14362": "足柄上郡大井町",
    "21203": "高山市",
    "34462": "世羅郡世羅町",
    "24204": "松阪市",
    "14104": "横浜市中区",
    "43510": "球磨郡相良村",
    "40100": "北九州市",
    "01206": "釧路市",
    "01633": "十勝総合振興局上士幌町",
    "11108": "さいたま市南区",
    "11327": "入間郡越生町",
    "14216": "座間市",
    "07209": "相馬市",
    "21208": "瑞浪市",
    "33681": "加賀郡吉備中央町",
    "43512": "球磨郡山江村",
    "47326": "中頭郡北谷町",
    "28443": "神崎郡福崎町",
    "35202": "宇部市",
    "19443": "北都留郡丹波山村",
    "01204": "旭川市",
    "41441": "藤津郡太良町",
    "14106": "横浜市保土ケ谷区",
    "14152": "相模原市中央区",
    "29385": "宇陀郡曽爾村",
    "11218": "深谷市",
    "15504": "刈羽郡刈羽村",
    "04203": "塩竈市",
    "40212": "大川市",
    "15205": "柏崎市",
    "43367": "玉名郡南関町",
    "09361": "下都賀郡壬生町",
    "33622": "勝田郡勝央町",
    "20430": "木曽郡大桑村",
    "07322": "安達郡大玉村",
    "16207": "黒部市",
    "26107": "京都市南区",
    "08203": "土浦市",
    "27128": "大阪市中央区",
    "13220": "東大和市",
    "26105": "京都市東山区",
    "19206": "大月市",
    "12226": "富津市",
    "28206": "芦屋市",
    "20361": "諏訪郡下諏訪町",
    "37204": "善通寺市",
    "10203": "桐生市",
    "16205": "氷見市",
    "09213": "那須塩原市",
    "06324": "西村山郡大江町",
    "22136": "浜松市浜北区",
    "24443": "多気郡大台町",
    "22461": "周智郡森町",
    "12236": "香取市",
    "11245": "ふじみ野市",
    "02321": "西津軽郡鰺ヶ沢町",
    "32203": "出雲市",
    "09301": "河内郡上三川町",
    "45401": "児湯郡高鍋町",
    "39410": "高岡郡日高村",
    "36202": "鳴門市",
    "20213": "飯山市",
    "30392": "日高郡日高川町",
    "40601": "田川郡香春町",
    "46206": "阿久根市",
    "01437": "空知総合振興局北竜町",
    "24461": "度会郡玉城町",
    "31204": "境港市",
    "11228": "志木市",
    "02446": "三戸郡階上町",
    "34203": "竹原市",
    "04321": "柴田郡大河原町",
    "36383": "海部郡牟岐町",
    "43208": "山鹿市",
    "01453": "上川総合振興局東神楽町",
    "46531": "大島郡天城町",
    "27147": "堺市美原区",
    "09343": "芳賀郡茂木町",
    "45443": "西臼杵郡五ヶ瀬町",
    "11238": "蓮田市",
    "01425": "空知総合振興局上砂川町",
    "01332": "渡島総合振興局福島町",
    "27145": "堺市南区",
    "01427": "空知総合振興局由仁町",
    "23441": "知多郡阿久比町",
    "05202": "能代市",
    "10382": "甘楽郡下仁田町",
    "12106": "千葉市美浜区",
    "20410": "下伊那郡根羽村",
    "12421": "長生郡一宮町",
    "22206": "三島市",
    "40447": "朝倉郡筑前町",
    "18501": "三方上中郡若狭町",
    "06202": "米沢市",
    "27208": "貝塚市",
    "39301": "安芸郡東洋町",
    "01209": "夕張市",
    "13100": "特別区部",
    "02203": "八戸市",
    "11348": "比企郡鳩山町",
    "45206": "日向市",
    "38203": "宇和島市",
    "43444": "上益城郡甲佐町",
    "37322": "小豆郡土庄町",
    "25442": "犬上郡甲良町",
    "30421": "東牟婁郡那智勝浦町",
    "12216": "習志野市",
    "03201": "盛岡市",
    "07204": "いわき市",
    "13210": "小金井市",
    "40342": "糟屋郡篠栗町",
    "27118": "大阪市城東区",
    "23110": "名古屋市中川区",
    "07505": "石川郡古殿町",
    "36468": "美馬郡つるぎ町",
  },
};

let Consts = {};
Consts.install = function(app) {
  app.config.globalProperties.$getPrefectureConst = (key) => {
    return CONSTS[key];
  };
};

export default Consts;
